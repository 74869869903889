import { isEmpty } from 'lodash-es';
import React from 'react';
import { CorContentstackLink } from 'corporate-components';
import { MainNavDrillDownMenu } from 'components/layout/cor-header/components';
import { IMainMenuLink, IMainMenuResource } from 'components/layout/cor-header/cor-header.interface';
import { MainNavResourceMenu } from 'components/layout/cor-header/components/main-navigation/main-nav-resource-menu';
import { isExternalUrl } from 'utils/is-external-url';
import { Routes } from 'constants/routes.enum';
import './main-nav-item-subnav.scss';

interface IMainNavItemSubnavProps {
  parent: string;
  label: string;
  url?: string;
  resourceMenus?: IMainMenuResource[];
  childItems: any;
  drillDownMenu: any;
  handleNavItemOpen?: () => void;
  menuId: string;
  closeHamburgerMenuHandler?: (url: string) => void;
}

export const MainNavItemSubnav: React.FC<IMainNavItemSubnavProps> = ({
  parent,
  label,
  url,
  resourceMenus = [],
  childItems = [],
  drillDownMenu = null,
  handleNavItemOpen,
  menuId = '',
  closeHamburgerMenuHandler,
}) => {
  const hasChildren: boolean = childItems?.length > 0;
  const hasResourceMenu: boolean = resourceMenus?.length > 0;
  const hasDrillDown: boolean = !isEmpty(url) && url?.toLowerCase() === Routes.ProductListPage;

  return (
    <div className={`main-subnav-content ${hasResourceMenu ? 'two-col' : 'one-col'}`}>
      {hasChildren && (
        <div className="main-subnav-content__links">
          {childItems.map((menu: IMainMenuLink, index: number) => (
            <section key={`${menu.label}_${index}`} className="main-subnav-content__link-panel">
              {menu.label && <header>{menu.label}</header>}
              <ul>
                {menu?.children &&
                  menu?.children?.length > 0 &&
                  menu.children.map((link: IMainMenuLink, index: number) => {
                    return (
                      <li key={`${link.url}_${index}`}>
                        <CorContentstackLink
                          data-testid={'main-subnav-content-link'}
                          contentKey={''}
                          data={{ href: link.url, title: link.label }}
                          aria-label={link.label}
                          opensInNewTab={link?.url ? isExternalUrl(link.url) : false}
                          onClick={() => closeHamburgerMenuHandler?.(link?.url as string)}
                        >
                          {link.label}
                        </CorContentstackLink>
                      </li>
                    );
                  })}
              </ul>
            </section>
          ))}
        </div>
      )}

      {hasDrillDown && drillDownMenu && (
        <div className="main-subnav-content__drill-down">
          <MainNavDrillDownMenu
            {...drillDownMenu}
            parent={parent}
            closeHamburgerMenuHandler={closeHamburgerMenuHandler}
          />
        </div>
      )}

      {hasResourceMenu && (
        <div className="main-subnav-content__highlights">
          <MainNavResourceMenu menu={resourceMenus} />
        </div>
      )}
    </div>
  );
};
