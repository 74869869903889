import { CorCheckboxField, CorSelectField, CorTextareaField, CorTextField } from 'corporate-components/cor-forms';

export const enum CorFormControlEnum {
  SingleLineEntry = 'Single Line Entry',
  CountryField = 'Country Entry Field',
  StateField = 'State Entry Field',
  ZipcodeField = 'Zipcode Entry Field',
  DropdownList = 'Dropdown List',
  EmailAddressEntryField = 'Email Address Entry Field',
  PhoneNumberEntryField = 'Phone Number Entry Field',
  MultiLineEntryField = 'Multi Line Entry Field',
  Checkbox = 'Checkbox',
  ConfirmField = 'Confirm Field',
  LATAM_Dropdown = 'LATAM Dropdown List',
}

export const enum CorFormUrlTarget {
  MAIL = '/bff/form/email',
  PARDOT = '/bff/form/pardot',
  SALESFORCE_EU = '/bff/form/salesforce/lead/eu',
}

export const enum CorFormDynamicLogic {
  AND = 'and',
  OR = 'or',
  AND_NOT = 'and_not',
}

export interface IFormMailTo {
  email: {
    email_to: string;
  };
}

export interface IFormHiddenFiledValue {
  key: string;
  value: string;
}

export interface IFormHiddenFiled {
  value?: [IFormHiddenFiledValue];
}

export const CorFormControlsSupportedSet = new Set([
  CorFormControlEnum.SingleLineEntry,
  CorFormControlEnum.CountryField,
  CorFormControlEnum.StateField,
  CorFormControlEnum.ZipcodeField,
  CorFormControlEnum.DropdownList,
  CorFormControlEnum.EmailAddressEntryField,
  CorFormControlEnum.PhoneNumberEntryField,
  CorFormControlEnum.MultiLineEntryField,
  CorFormControlEnum.Checkbox,
  CorFormControlEnum.ConfirmField,
  CorFormControlEnum.LATAM_Dropdown,
]);

export const CorFormControlsComponentMap = new Map([
  [CorFormControlEnum.SingleLineEntry, CorTextField],
  [CorFormControlEnum.CountryField, CorTextField],
  [CorFormControlEnum.StateField, CorTextField],
  [CorFormControlEnum.ZipcodeField, CorTextField],
  [CorFormControlEnum.DropdownList, CorSelectField],
  [CorFormControlEnum.EmailAddressEntryField, CorTextField],
  [CorFormControlEnum.PhoneNumberEntryField, CorTextField],
  [CorFormControlEnum.MultiLineEntryField, CorTextareaField],
  [CorFormControlEnum.Checkbox, CorCheckboxField],
  [CorFormControlEnum.ConfirmField, CorTextField],
  [CorFormControlEnum.LATAM_Dropdown, CorSelectField],
]);

export const enum CorFormContentStack {
  BACKGROUND_COLOR_KEY = 'form.container_background_color',
  TITLE_KEY = 'form.form.0.title',
  FORM_CONTROLS_KEY = 'form.form.0.form_field',
  DESCRIPTION_KEY = 'form.form.0.rich_text_editor',
  ERROR_MESSAGE_KEY = 'form.form.0.error_messages',
  ERROR_TWO_MESSAGES_KEY = 'form.form.0.error_two',
  SUBMIT_BUTTON_KEY = 'form.form.0.button.button',
  FORM_URL_KEY = 'form.form.0.form_target',
  FORM_ID = 'form.form.0.form_id',
  ENABLE_CAPTCHA = 'form.form.0.enable_captcha',
  EMAIL_TO = 'form.form.0.modular_blocks',
  HIDDEN_FIELD = 'form.form.0.hidden_field',
}

export const duplicateIndex = '--';
