import React from 'react';
import config from 'config/app';
import { Endpoints } from 'constants/endpoints.enum';
import { flush as flushAnalyticsSessionVars } from 'utils/analytics/cache-preload';
import { isExternalUrl } from './is-external-url';

export const onNestedLinkClick = (
  e: React.SyntheticEvent<HTMLDivElement>,
  forceExternal = false,
  callback?: () => void
) => {
  const href = (e.target as HTMLDivElement).getAttribute('href') || '';

  if (href) {
    e.stopPropagation();
    if (isExternalUrl(href) || forceExternal) {
      e.preventDefault();
      window.open(href, '_blank');
      callback && callback();
    } else {
      window.location.assign(href);
    }
  }
};

export const logOutClickHandler = (e: React.SyntheticEvent<HTMLAnchorElement>): void => {
  e.preventDefault();
  window.location.href = `${config.apiUrl}${Endpoints.LOGOUT}`;
  sessionStorage.setItem('quote-notification-state', 'true');
  flushAnalyticsSessionVars();
};

export const isAbsoluteLink = (linkUrl?: string) => {
  return !!linkUrl && /^https?:\/\//i.test(linkUrl);
};
