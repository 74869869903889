import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { CustomRadioButton } from 'components/custom-radio-button';
import { CorButton } from 'components/cor-button';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { IOptionType } from 'components/custom-dropdown';
import { ILimitOption } from 'features/product-list/components/product-list-limit-control';
import './select-option-modal.scss';

export interface IActionOption {
  label: string;
  value: () => void;
  isDisabled?: boolean;
}

export interface ISelectOptionModalProps extends IModalProps {
  contentstackModalTitlePath?: string;
  options: ILimitOption[] | IOptionType[] | IActionOption[];
  defaultSelectedOption: IActionOption;
  usageContext?: string;
  areOptionsClickable: boolean;
  className?: string;
  handler: (selectedOption: IActionOption) => void;
  hasCancelButton?: boolean;
}

export const SelectOptionModal: FC<ISelectOptionModalProps> = ({
  isOpen = false,
  onClose = () => {},
  contentstackModalTitlePath,
  options,
  defaultSelectedOption,
  usageContext,
  areOptionsClickable,
  className,
  handler,
  hasCancelButton = false,
  ...modalProps
}) => {
  const [selectedOption, setSelectedOption] = useState<IActionOption>(defaultSelectedOption);

  useEffect(() => {
    setSelectedOption(defaultSelectedOption);
  }, [defaultSelectedOption]);

  const handleSelectModalClose = () => {
    onClose();
  };

  const modalClassName = classNames('select-action-modal', className);

  return (
    <Modal className={modalClassName} isOpen={isOpen} onClose={handleSelectModalClose} {...modalProps}>
      <ModalHeader
        titleContentstackPath={
          contentstackModalTitlePath ? contentstackModalTitlePath : 'modals.0.select_option_modal.0.heading'
        }
      />
      <div className="select-option-modal__options">
        {options.map((option) => (
          <CustomRadioButton
            key={option.label}
            disabled={option.isDisabled}
            checked={selectedOption?.label === option.label}
            onChange={() => setSelectedOption(option)}
          >
            {option.label}
          </CustomRadioButton>
        ))}
      </div>
      <ModalFooter className="modal-footer__vertical-buttons">
        <CorButton
          className="select-option-modal__button"
          color="primary"
          onClick={() => {
            handleSelectModalClose();
            handler(selectedOption);
          }}
        >
          <ContentstackText contentKey="modals.0.select_option_modal.0.submit_button" />
        </CorButton>
        {hasCancelButton && (
          <div className="select-option-modal__cancel-button">
            <CorButton className="text" onClick={onClose}>
              <ContentstackText contentKey="modals.0.select_option_modal.0.cancel_button" />
            </CorButton>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};
