import { FC, useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ErrorNotification } from 'components/forms/error-notification';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { createValidationSchema, payAmountValidator, validateFormik } from 'utils/validation';
import { useContent } from 'hooks/use-content';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import { TextField } from 'components/forms/text-field';
import { CorButton } from 'components/cor-button';
import { PaymentMethodsDropdown } from 'features/payment-methods-dropdown';
import { ModalHeader, ModalFooter } from 'components/modals/common/modal/components';
import {
  clearPaymentTransactionErrors,
  submitAchPayment,
  submitCreditCardPayment,
  submitPadPayment,
} from 'store/payment-transactions/actions';
import { selectInvoicesSummary } from 'store/invoices/selectors';
import { getSurcharge, resetPaymentSummaryCalculations } from 'store/invoices/actions';
import { PaymentMethods } from 'constants/payment-methods.enum';
import { LoadingIndicator } from 'components/loading-indicator';
import { selectPaymentTransactionLoading } from 'store/payment-transactions/selectors';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { Routes } from 'constants/routes.enum';
import { selectIsUserCountryCanada } from 'store/auth/selectors';
import { errorTwoMessages } from 'constants/errors';
import { ISelectedPaymentMethod } from 'store/invoices/reducers';
import { ISubmitPadPaymentPayload } from 'store/payment-transactions/sagas/submit-pad-payment';
import { ISubmitAchPaymentPayload } from 'store/payment-transactions/sagas/submit-ach-payment';
import { CreditCardSurchargeText } from 'components/credit-card-surcharge-text';

import './make-deposit-modal.scss';

export interface IMakeDepositValues {
  payAmount: string;
  comments: string;
}

export interface IMakeDepositProps extends IModalProps {
  isOpen: boolean;
}

export const MakeDepositModal: FC<IMakeDepositProps> = ({ isOpen, onClose = () => {} }) => {
  const ONLY_DIGITS_REG = /^(0*[1-9][0-9]*([.,][0-9]*)?|0*[.,][0-9]*[1-9][0-9]*)$/;
  const commentsInputMaxLength = 120;

  const { getMessageText, getContentByKey } = useContent();
  const dispatch = useDispatch();
  const history = useHistory();
  const contentstackPath = 'modals.0.make_deposit_modal.0';
  const contentstackPathPaymentMethod = 'tabs[6].payment.content[0].invoices[0].payment_summary[0]';
  const [submitted, setSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const clientErrorMessage = getMessageText('error', 'MSG190');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<ISelectedPaymentMethod | null>(null);
  const invoicesSummary = useSelector(selectInvoicesSummary);
  const isPaymentTransactionLoading = useSelector(selectPaymentTransactionLoading);
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const selectDropdownErrorMessage = getMessageText('error_two', 'MSG222');

  const showSuccessMakeDepositModal = useSuccessErrorMessageModal({
    type: 'success',
    messageId: 'MSG203',
    hasCrossButton: true,
  });

  const showFailMakeDepositModal = useSuccessErrorMessageModal({
    type: 'error',
    hasCrossButton: true,
  });

  const validationSchema = createValidationSchema({
    payAmount: payAmountValidator({
      required: getMessageText('error', 'MSG184'),
      wrongFormat: getMessageText('error', 'MSG185'),
    }),
  });

  const formInitialValues: IMakeDepositValues = {
    payAmount: '',
    comments: '',
  };

  const validateForm = (values: IMakeDepositValues) => {
    validateFormik(values, validationSchema, setHasErrors);
  };

  const clearErrors = useCallback(() => {
    setSubmitted(false);
  }, []);

  const submitDeposit = (values: IMakeDepositValues) => {
    if (submitted) return;
    setSubmitted(true);
    onSubmitForm(values);
  };

  const recalculateSurcharge = (paymentMethod: ISelectedPaymentMethod | null, payAmount: string) => {
    if (paymentMethod && payAmount) {
      const isPaymentMethodCreditCard = !isEmpty(paymentMethod['cardType']);

      dispatch(
        getSurcharge.request({
          invoiceBalances: [Number(payAmount.replace(',', '.'))],
          paymentMethod: isPaymentMethodCreditCard ? PaymentMethods.CREDIT_CARD : PaymentMethods.ACH_BANK_ACCOUNT,
        })
      );
    }
  };

  const onFailedSubmitPayment = (errorMessageId: string) => {
    const transactionFailedMessageType = errorTwoMessages.includes(errorMessageId) ? 'error_two' : 'error';
    showFailMakeDepositModal({
      type: transactionFailedMessageType,
      modalTitle: getContentByKey('modals.0.success_error_modal.0.error_title', ''),
      hasCrossButton: true,
      autoClose: false,
      children: <ContentstackMessage type={transactionFailedMessageType} messageId={errorMessageId ?? 'MSG189'} />,
    });
  };

  const onSubmitForm = ({ payAmount, comments }: { payAmount: string; comments: string }) => {
    const depositAmount = Number(payAmount.replace(',', '.'));

    if (selectedPaymentMethod) {
      if ('cardType' in selectedPaymentMethod) {
        dispatch(
          submitCreditCardPayment.request({
            depositAmount,
            isDeposit: true,
            cardLastFourDigits: selectedPaymentMethod?.cardLastFourDigits,
            cardType: selectedPaymentMethod?.cardType,
            depositComment: comments.trim(),
            onSuccessCallBack: onSuccessfulSubmitPayment,
            onFailCallBack: onFailedSubmitPayment,
          })
        );
      }

      if ('accountType' in selectedPaymentMethod) {
        dispatch(
          !isUserCountryCanada
            ? submitAchPayment.request<ISubmitAchPaymentPayload>({
                depositAmount,
                isDeposit: true,
                accountType: selectedPaymentMethod?.accountType,
                publicAccountNumber: selectedPaymentMethod?.publicAccountNumber,
                publicRoutingNumber: selectedPaymentMethod?.publicRoutingNumber,
                depositComment: comments.trim(),
                onSuccessCallBack: onSuccessfulSubmitPayment,
                onFailCallBack: onFailedSubmitPayment,
              })
            : submitPadPayment.request<ISubmitPadPaymentPayload>({
                depositAmount,
                isDeposit: true,
                accountType: selectedPaymentMethod.accountType,
                publicAccountNumber: selectedPaymentMethod.publicAccountNumber,
                routingNumber: selectedPaymentMethod.publicRoutingNumber,
                depositComment: comments.trim(),
                onSuccessCallBack: onSuccessfulSubmitPayment,
                onFailCallBack: onFailedSubmitPayment,
              })
        );
      }
    }
  };

  const getRoundAndTwoDigitAfterSeparator = (value: string) =>
    (Math.round(Number(value.replace(',', '.')) * 100) / 100).toFixed(2);

  const onSuccessfulSubmitPayment = () => {
    dispatch(resetPaymentSummaryCalculations());
    showSuccessMakeDepositModal();
  };

  const onCancel = () => {
    dispatch(resetPaymentSummaryCalculations());
    dispatch(clearPaymentTransactionErrors());
    onClose();
  };

  const addNewPaymentOptionHandler = () => {
    const paymentsUrl = getLocalizedShopUrl(Routes.AccountPayments);

    history.push(`${paymentsUrl}?selectedView=Methods`);
    onCancel();
  };

  const paymentMethodChangeHandler = (paymentMethod: ISelectedPaymentMethod, payAmount: string) => {
    setSelectedPaymentMethod(paymentMethod);
    recalculateSurcharge(paymentMethod, payAmount);
  };

  return (
    <>
      <Modal
        size="medium"
        className="make-deposit-modal"
        isOpen={isOpen}
        onClose={onCancel}
        hideCloseButton={isPaymentTransactionLoading}
        withBackdropClick={!isPaymentTransactionLoading}
      >
        {isPaymentTransactionLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ModalHeader className="make-deposit-modal__header" titleContentstackPath={`${contentstackPath}.header`} />

            <div className="make-deposit-modal__wrapper">
              <div className="add-new-bank-account-modal__description">
                <ContentstackText contentKey={`${contentstackPath}.description`} />
              </div>

              {submitted && hasErrors && (
                <div className="grid-x">
                  <div className="cell">
                    <ErrorNotification className="error-notification" message={clientErrorMessage} />
                  </div>
                </div>
              )}
              <Formik
                validateOnChange={false}
                onSubmit={submitDeposit}
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                validate={validateForm}
              >
                {({ dirty, isValid, values, setFieldValue, setErrors, errors, handleBlur }) => {
                  return (
                    <Form
                      className="make-deposit-modal__form"
                      onChange={(event) => {
                        clearErrors();
                        const fieldName = (event.target as HTMLInputElement).name;
                        setErrors(omit(errors, fieldName));
                      }}
                    >
                      <div className="make-deposit-modal__field">
                        <PaymentMethodsDropdown
                          contentstackPath={`${contentstackPathPaymentMethod}.page_content`}
                          selectedPaymentMethod={selectedPaymentMethod}
                          onPaymentMethodChange={(paymentMethod: ISelectedPaymentMethod) =>
                            paymentMethodChangeHandler(paymentMethod, values.payAmount)
                          }
                          onAddNewPaymentOption={addNewPaymentOptionHandler}
                          dropdownErrorMessage={selectDropdownErrorMessage}
                          ignoreInvoiceSummaryRequest
                        />
                      </div>
                      <div className="make-deposit-modal__credit-card-surcharge">
                        {getContentByKey<string>(`${contentstackPath}.credit_card_surcharge`, '')}
                        <span>{` $${invoicesSummary.surcharges}`}</span>
                      </div>
                      <div className="make-deposit-modal__amount-filed">
                        <TextField
                          className="make-deposit-modal__field"
                          id="payAmount"
                          adornment={'$'}
                          label={getContentByKey<string>(`${contentstackPath}.pay_amount`, '')}
                          name="payAmount"
                          placeholder={getContentByKey<string>(`${contentstackPath}.pay_amount_placeholder`, '')}
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;

                            if (ONLY_DIGITS_REG.test(value.toString())) setFieldValue('payAmount', value);

                            if (!value) setFieldValue('payAmount', formInitialValues.payAmount);
                          }}
                          onBlur={(e) => {
                            const { value } = e.target;

                            if (!value) {
                              handleBlur(e);
                              return;
                            }

                            const formattedValue = getRoundAndTwoDigitAfterSeparator(value);
                            const fieldValue = isUserCountryCanada
                              ? formattedValue.replace('.', ',')
                              : formattedValue.replace(',', '.');

                            setFieldValue('payAmount', fieldValue);
                            recalculateSurcharge(selectedPaymentMethod, formattedValue);
                          }}
                        />
                      </div>
                      <div className="make-deposit-modal__comments-filed">
                        <TextField
                          as="textarea"
                          name="comments"
                          rows="2"
                          cols="50"
                          className="make-deposit-modal__field make-deposit-modal__comment-field"
                          maxLength={commentsInputMaxLength}
                          label={getContentByKey<string>(`${contentstackPath}.comments`, '')}
                          placeholder={getContentByKey<string>(`${contentstackPath}.comments_placeholder`, '')}
                        />
                      </div>
                      <CreditCardSurchargeText contentstackPath={contentstackPath} />
                      <ModalFooter
                        className="make-deposit-modal__footer"
                        contentstackPath={contentstackPath}
                        cancelButtonHandler={onCancel}
                        hasCancelButton
                      >
                        <CorButton
                          className="make-deposit-modal__save_button"
                          disabled={submitted || !selectedPaymentMethod || !(isValid && dirty)}
                          type="submit"
                        >
                          <ContentstackText contentKey={`${contentstackPath}.save_button_label`} />
                        </CorButton>
                      </ModalFooter>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
