import { useContext, useEffect, useState, MutableRefObject } from 'react';
import { ceil } from 'lodash-es';
import { StickySectionKeysType, StickySectionsHeightsStateContext } from 'context/sticky-sections-heights-context';
import { useBreakpoint } from 'hooks/use-breakpoint';

interface IUseStickyOffsetsParams {
  ref?: MutableRefObject<HTMLElement | null>;
  sectionKey?: StickySectionKeysType;
  isBannerExpanded?: boolean;
  isDesktopOnly?: boolean;
  additionalSpacing?: number;
  additionalOffset?: number;
  updateHeightDependency?: string | boolean;
}

export const useStickyOffsets = (
  // this hook can be used with or without parameters. in case you need to set sticky section height,
  // please pass ref, action, sectionKey etc. If you only need to use getters (getOffset, getCurrentPageStickySectionsHeight),
  // you can skip parameters. the code below exists for typescript to accepts such behaviour
  props: IUseStickyOffsetsParams = {
    ref: undefined,
    sectionKey: undefined,
    isBannerExpanded: false,
    isDesktopOnly: false,
    additionalSpacing: 0,
    additionalOffset: 0,
    updateHeightDependency: undefined,
  }
) => {
  const {
    ref,
    sectionKey,
    isBannerExpanded = false,
    isDesktopOnly = false,
    additionalSpacing = 0,
    additionalOffset = 0,
    updateHeightDependency,
  } = props;

  const [sectionHeight, setSectionHeight] = useState(0);
  const { setStickySectionHeight, getOffset, getCurrentPageStickySectionsHeight } = useContext(
    StickySectionsHeightsStateContext
  );
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (!ref?.current) return;

    if (isDesktopOnly && isMobile) {
      return;
    }

    setSectionHeight(ref?.current.offsetHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref?.current?.offsetHeight, isMobile, window.screen.orientation, updateHeightDependency]);

  useEffect(() => {
    if (!ref?.current) return;

    if (isDesktopOnly && isMobile) {
      return;
    }

    if (sectionKey) {
      setStickySectionHeight({
        sectionHeight: ceil(sectionHeight + additionalSpacing),
        sectionKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionHeight, isBannerExpanded, isDesktopOnly, isMobile, window.screen.orientation]);

  return {
    offset: sectionKey && getOffset(sectionKey) + additionalOffset,
    getCurrentPageStickySectionsHeight,
    getOffset,
    setStickySectionHeight,
  };
};
