import { FC } from 'react';
import classNames from 'classnames';
import { CorContentstackImage } from 'corporate-components';
import './header-icon.scss';
import { ICSCorImage } from 'corporate-interfaces';

export interface ICartLinkProps {
  iconContentKey: string;
  data?: ICSCorImage | null;
  customClass?: string;
  counter?: number;
}

export const HeaderIcon: FC<ICartLinkProps> = ({ iconContentKey, data, customClass = '', counter = undefined }) => {
  const iconClass = classNames('header-icon', customClass);
  const counterWidth = !!counter ? 10 + counter.toString().length * 5 : 0;
  return (
    <div className={iconClass}>
      <CorContentstackImage contentKey={iconContentKey} data={data} />
      {counter !== undefined && (
        <div className={'header-icon__counter'} style={{ width: `${counterWidth}px` }}>
          <span>{counter}</span>
        </div>
      )}
    </div>
  );
};
