import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ISku } from 'types/product';
import { IOpenCreateListModalHandler } from 'components/modals/add-items-to-shopping-list-modal';
import { getAllShoppingLists } from 'store/shopping-lists/actions';
import {
  useAddItemsToShoppingListModal,
  useCreateShoppingListModal,
  useSuccessErrorMessageModal,
} from './use-global-modal';

interface IUseAddToShoppingListProps {
  openCreateListModalHandler?: ({ sku }: IOpenCreateListModalHandler) => void;
  addLineItemsToShoppingLists?: (selectedShoppingLists: string[], shoppingListId?: string, selectedSku?: ISku) => void;
  createListHandler?: (values: { shoppingListName: string }, selectedSku?: ISku | undefined) => void;
  forceInitialRequest?: boolean;
}

export const useAddToShoppingList = (props: IUseAddToShoppingListProps) => {
  const {
    openCreateListModalHandler,
    addLineItemsToShoppingLists,
    createListHandler,
    forceInitialRequest = true,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (forceInitialRequest) {
      dispatch(getAllShoppingLists.request());
    }
  }, [dispatch, forceInitialRequest]);

  const showAddItemsToShoppingListModal = useAddItemsToShoppingListModal({
    openCreateListModalHandler: openCreateListModalHandler,
    addLineItemsToShoppingListsHandler: addLineItemsToShoppingLists,
  });

  const showCreateShoppingListModal = useCreateShoppingListModal({
    submitHandler: createListHandler,
  });

  const showAddedItemsSuccessfullyModal = useSuccessErrorMessageModal({
    type: 'success',
    hasCrossButton: true,
  });

  const showAddedItemsFailModal = useSuccessErrorMessageModal({
    type: 'error',
    hasCrossButton: true,
    autoClose: false,
  });

  return {
    showCreateShoppingListModal,
    showAddItemsToShoppingListModal,
    showAddedItemsSuccessfullyModal,
    showAddedItemsFailModal,
  };
};
