import { action } from 'typesafe-actions';
import { CancelToken, Method } from 'axios';
import { IGeneratedActions } from 'types/actions';
import types from './action-types';

export const doRequest = <P>(
  actions: IGeneratedActions,
  url: string,
  method: Method,
  data?: P,
  headers?: object,
  cancelToken?: CancelToken
) => action(types.API_DO_REQUEST, { actions, data, url, method, headers, cancelToken });
