import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getSkuReplacementsActionConstants, getSkuReplacements } from 'store/items-replacements/actions';
import { IAction } from 'types/actions';
import { ISkuInformation } from 'types/order-details';

export interface IGetSkuReplacementsPayload {
  skus: string;
  onSuccessCallback?: (payload: ISkuInformation[]) => void;
}

export function* getSkuReplacementsHandler({ payload: { skus } }: IAction<IGetSkuReplacementsPayload>) {
  const url = `${Endpoints.CATALOG_SKUS}/${skus}`;
  yield put(doRequest(getSkuReplacements, url, 'get'));
}

export function* getSkuReplacementsSuccessCallBackSagaHandler({ payload }: IAction<IGetSkuReplacementsPayload>) {
  const data = yield take(getSkuReplacementsActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallback) {
    payload.onSuccessCallback(data.payload);
  }
}

export default function* getSkuReplacementsSaga() {
  yield takeLatest(getSkuReplacementsActionConstants[Actions.REQUEST], getSkuReplacementsHandler);
  yield takeLatest(getSkuReplacementsActionConstants[Actions.REQUEST], getSkuReplacementsSuccessCallBackSagaHandler);
}
