import { contentstackConfig } from 'constants/contentstack';
import { ShopContentTypes } from 'constants/live-preview-shop';
import { Home } from 'pages/home/home';
import { ASpotBanner } from 'pages/home/components/a-spot-banner';
import { FeaturedProductsCarousel } from 'pages/home/components/featured-products-carousel';
import { NavigationTiles } from 'components/navigation-tiles';
import { BuyItAgain } from 'features/order-updates/components/buy-it-again';
import { ShoppingList } from 'features/order-updates/components/shopping-list';
import { RecentOrders } from 'features/order-updates/components/recent-orders';
import { NewsSection } from 'features/order-updates/components/news-section';
import { OrderCutoffNotification } from 'components/order-cutoff-notification';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { IAnyObject } from 'types/generic-types';

const LIVE_PREVIEW_MAP = {
  order_updates: {
    contentType: 'order_updates',
    component: RecentOrders,
    reference: ['recent_orders.reorder_success_pop_up'],
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          order_updates: [{ ...entry }],
        },
      };
    },
  },
  buy_it_again: {
    contentType: 'buy_it_again',
    component: BuyItAgain,
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          buy_it_again: [{ ...entry }],
        },
      };
    },
  },
  shopping_list: {
    contentType: 'shopping_list',
    component: ShoppingList,
    reference: ['empty_shopping_list.guide_tiles'],
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          shopping_list: [{ ...entry }],
        },
      };
    },
  },
  order_cutoff_notification: {
    contentType: 'order_cutoff_notification',
    component: () => <OrderCutoffNotification page={AccessDefinitions.HOME_ACTIONS} />,
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          order_cutoff_notification: [{ ...entry }],
        },
      };
    },
  },
  news_section: {
    contentType: 'news_and_notifications_list',
    component: NewsSection,
    reference: ['news_and_notifications'],
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          news_section: {
            news_and_notifications_list: [{ ...entry }],
          },
        },
      };
    },
  },
  a_spot_banner: {
    contentType: 'a_spot_banner',
    component: ASpotBanner,
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          a_spot_banner: [{ ...entry }],
        },
      };
    },
  },
  'products-carousel': {
    contentType: 'products_carousel',
    component: FeaturedProductsCarousel,
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          products_carousel: [{ ...entry }],
        },
      };
    },
  },
  navigation_tiles_list: {
    contentType: 'navigation_tiles_list',
    component: () => <NavigationTiles contentstackPath="page_content.navigation_tiles_list.0" />,
    reference: ['navigation_tile_item', 'navigation_tile_item.video'],
    data: (entry: IAnyObject) => {
      return {
        page_content: {
          navigation_tiles_list: [{ ...entry }],
        },
      };
    },
  },
};

LIVE_PREVIEW_MAP[contentstackConfig.home.contentTypeName] = {
  contentType: contentstackConfig.home.contentTypeName,
  component: Home,
  reference: [...contentstackConfig.home.include],
  data: (entry: IAnyObject) => {
    return {
      ...entry,
      contentTypeName: contentstackConfig.home.contentTypeName,
    };
  },
};

export const UseShopLivePreview = (contentTypeUrl) => {
  const contentType = ShopContentTypes.get(contentTypeUrl) || '';

  return LIVE_PREVIEW_MAP[contentType];
};
