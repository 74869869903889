import { takeLatest, put, select } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getUserInfo, getUserInfoActionConstants } from 'store/auth/actions';
import { IAction } from 'types/actions';
import { IUserInfo } from 'types/user-info';
import { initializeGTM, sendDeletedLocationStatus, sendInternalUserRole } from 'utils/analytics';
import { Roles } from 'constants/roles.enum';
import { selectIsImpersonateModeOn, selectIsUserLocationDeleted } from 'store/auth/selectors';

export function* getUserInfoSagaHandler() {
  yield put(doRequest(getUserInfo, Endpoints.CUSTOMER_INFO, 'get'));
}

export function* getUserInfoSuccessHandler({ payload }: IAction<IUserInfo>) {
  if (!!payload.soldToId) {
    initializeGTM({
      userId: payload.soldToId,
      dcName: payload.dcName,
      dcNumber: payload.dcNumber,
      soldToDcNumber: payload.soldToDcNumber,
      soldToDcName: payload.soldToDcName,
      shipToNumber: payload.locationExternalId,
    });
  }

  const isImpersonateModeOn = yield select(selectIsImpersonateModeOn);
  if (isImpersonateModeOn) {
    const internalUserRole = [Roles.MSR, Roles.CSR, Roles.TSR, Roles.MSM].find((role) =>
      payload?.roles?.includes(role)
    );
    sendInternalUserRole(internalUserRole);
  }
  const isUserLocationDeleted = yield select(selectIsUserLocationDeleted);
  if (isUserLocationDeleted) {
    sendDeletedLocationStatus(isUserLocationDeleted);
  }
}

export default function* getUserInfoSaga() {
  yield takeLatest(getUserInfoActionConstants[Actions.REQUEST], getUserInfoSagaHandler);
  yield takeLatest(getUserInfoActionConstants[Actions.SUCCESS], getUserInfoSuccessHandler);
}
