import { FC } from 'react';
import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import { selectUpdatedUserLocations } from 'store/account/selectors';
import { ILocationInformation } from 'store/account/reducer';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackMessage } from 'components/contentstack';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './edit-user-info-success-message-modal.scss';

export interface IEditUserInfoSuccessMessageModalProps extends IModalProps {
  userHasBeenRemoved?: boolean;
  userInfoHasBeenUpdated?: boolean;
  userHasBeenUnsubscribed?: boolean;
}

export const EditUserInfoSuccessMessageModal: FC<IEditUserInfoSuccessMessageModalProps> = ({
  isOpen = false,
  userHasBeenRemoved = false,
  userInfoHasBeenUpdated = false,
  userHasBeenUnsubscribed = false,
  onClose = () => {},
}) => {
  const contentstackPath = 'modals.0.success_error_modal.0';
  const updatedLocations = useSelector(selectUpdatedUserLocations);
  const removedLocations = updatedLocations?.removedLocations || [];
  const assignedLocations = updatedLocations?.assignedLocations || [];

  const renderLocationsList = (locations: ILocationInformation[]) => (
    <ul className="edit-user-info-success-message-modal__locations">
      {locations.map((location: ILocationInformation) => (
        <li key={location.locationId} className="edit-user-info-success-message__location">
          {location.name}
        </li>
      ))}
    </ul>
  );

  return (
    <Modal className="edit-user-info-success-message" isOpen={isOpen} onClose={onClose} withBackdropClick>
      <ModalHeader iconType="success" contentstackPathIcon={`${contentstackPath}.success_icon`}>
        {userHasBeenRemoved ? (
          <ContentstackMessage type="success" messageId="MSG104" />
        ) : (
          <ContentstackMessage type="success" messageId="MSG131" />
        )}
      </ModalHeader>
      <div className="edit-user-info-success-message__message">
        {userHasBeenRemoved ? (
          <ContentstackMessage type="success" messageId="MSG105" />
        ) : (
          <>
            {userInfoHasBeenUpdated && (
              <p>
                <ContentstackMessage type="success" messageId="MSG130" />
              </p>
            )}
            {userHasBeenUnsubscribed && (
              <p>
                <ContentstackMessage type="success" messageId="MSG144" />
              </p>
            )}
            {!isEmpty(removedLocations) && (
              <>
                <ContentstackMessage type="success" messageId="MSG045a" />
                {renderLocationsList(removedLocations)}
              </>
            )}
            {!isEmpty(assignedLocations) && (
              <>
                <ContentstackMessage type="success" messageId="MSG045b" />
                {renderLocationsList(assignedLocations)}
              </>
            )}
          </>
        )}
      </div>
      <ModalFooter contentstackPath={contentstackPath} closeButtonHandler={onClose} hasCloseButton />
    </Modal>
  );
};
