import { push } from 'redux-first-history';
import { takeLatest, put } from 'redux-saga/effects';
import { Routes } from 'constants/routes.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { setResetPasswordLinkExpired, validateToken, validateTokenActionConstants } from 'store/reset-password/actions';
import { IAction } from 'types/actions';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';

export interface IValidateTokenPayload {
  token: string;
}

export function* validateTokenSagaHandler({ payload }: IAction<IValidateTokenPayload>) {
  yield put(doRequest(validateToken, `${Endpoints.PASSWORDS}/${payload.token}`, 'get'));
}

export function* validateTokenFailSagaHandler({ error }: { error: { status: number }; type: string }) {
  const locale = localStorage.getItem('locale');
  const isCanadaFrenchLocale = locale?.includes(CorContentStackLanguages.FRENCH_CANADA);
  const resetPasswordPage = isCanadaFrenchLocale
    ? Routes.CanadaFrenchResetPasswordVerificationPage
    : Routes.ResetPasswordVerificationPage;

  if (error.status === 404) {
    yield put(push(getLocalizedShopUrl(resetPasswordPage)));
    yield put(setResetPasswordLinkExpired(true));
  } else {
    yield put(push(getLocalizedShopUrl(isCanadaFrenchLocale ? Routes.CanadaFrenchLoginPage : Routes.LoginPage)));
  }
}

export default function* validateTokenSaga() {
  yield takeLatest(validateTokenActionConstants[Actions.REQUEST], validateTokenSagaHandler);
  yield takeLatest(validateTokenActionConstants[Actions.FAIL], validateTokenFailSagaHandler);
}
