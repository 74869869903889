import { mapFeaturedContentEntries } from 'corporate-components/cor-featured-content/cor-featured-content.mapper';
import { IAlgoliaCards } from 'queries/use-algolia-query';
import { IAnyObject } from 'types/generic-types';
import {
  CorFeaturedContentContentTypeUidEnum,
  CorFeaturedContentContentTypeUidMap,
} from 'constants/cor-featured-content.enum';
import { IContentstackSearchQueryQuery } from './use-contentstack-search-query-query.interface';

const contentstackSearchQueryQuerySelect = ({ contentTypeName = CorFeaturedContentContentTypeUidEnum.News }) => (
  response: IAnyObject
): IContentstackSearchQueryQuery => {
  const contentType = CorFeaturedContentContentTypeUidMap.get(contentTypeName);
  const contentstackCards = mapFeaturedContentEntries({
    entries: response?.entries,
    contentType,
  }) as IAlgoliaCards;

  return { contentstackCards };
};

export default contentstackSearchQueryQuerySelect;
