import './cor-three-column.scss';
import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { CorThreeColumnTextTile } from 'corporate-components/cor-three-column/components/cor-three-column-text-tile';
import { CorThreeColumnTile } from 'corporate-components/cor-three-column/components/cor-three-column-tile';
import { ICSCorButton, ICSCorImage } from 'corporate-interfaces';
import { CorTypography } from 'corporate-ui';
import { get } from 'lodash-es';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { CorButtonLink } from 'components/cor-button-link';
import { useContent } from 'hooks/use-content';

const BASE_KEY = 'three_column_content';
const BASE_COLUMN_KEY = 'three_column_content.three_column_content[0]';

export type ColumnStyleType = 'tiles' | 'columns' | string;

interface ICSThreeColumnButtonRow {
  row_label: string;
  button: ICSCorButton[];
}

export interface ICSIconText {
  icon?: ICSCorImage;
  text?: string;
  button?: ICSCorButton;
}

export interface ICSThreeColumnTileProps extends PropsWithChildren {
  icon_text: ICSIconText;
  direction: 'left' | 'right';
  row: number;
  rich_text?: string;
}

export interface ICSThreeColumnBlockData {
  left_column_content?: ICSThreeColumnTileProps[];
  middle_column_content?: ICSThreeColumnTileProps[];
  right_column_content?: ICSThreeColumnTileProps[];
  button_row?: ICSThreeColumnButtonRow[];
  style?: ColumnStyleType;
}

export interface ICSThreeColumnData {
  three_column_content?: ICSThreeColumnBlockData[];
  container_background_color?: string;
}

const RenderTiles: FC<ICSThreeColumnBlockData> = ({ middle_column_content, right_column_content }) => {
  return (
    <div data-testid={'cor-three-column__tiles'} className="cor-three-column__column-grid">
      {middle_column_content?.length
        ? middle_column_content.map((content, index) => (
            <CorThreeColumnTile key={index.toString()} {...content} row={index + 1} direction={'left'}>
              {content?.rich_text && (
                <CorContentstackHtml contentKey={`${BASE_COLUMN_KEY}.middle_column_content[${index}].rich_text`} />
              )}
            </CorThreeColumnTile>
          ))
        : null}

      {right_column_content?.length
        ? right_column_content.map((content, index) => (
            <CorThreeColumnTile key={index.toString()} {...content} row={index + 1} direction={'right'}>
              {content?.rich_text && (
                <CorContentstackHtml contentKey={`${BASE_COLUMN_KEY}.right_column_content[${index}].rich_text`} />
              )}
            </CorThreeColumnTile>
          ))
        : null}
    </div>
  );
};

const RenderColumns: FC<ICSThreeColumnBlockData> = ({ middle_column_content, right_column_content }) => {
  return (
    <div data-testid={'cor-three-column__columns'} className={'cor-three-column__text-columns'}>
      <div className="cor-three-column__text-column cor-three-column__text-column-middle">
        {middle_column_content?.length
          ? middle_column_content.map((content, index) => (
              <CorThreeColumnTextTile key={index.toString()} {...content}>
                {content?.rich_text && (
                  <CorContentstackHtml contentKey={`${BASE_COLUMN_KEY}.middle_column_content[${index}].rich_text`} />
                )}
              </CorThreeColumnTextTile>
            ))
          : null}
      </div>
      <div className="cor-three-column__text-column cor-three-column__text-column-right">
        {right_column_content?.length
          ? right_column_content.map((content, index) => (
              <CorThreeColumnTextTile key={index.toString()} {...content}>
                {content?.rich_text && (
                  <CorContentstackHtml contentKey={`${BASE_COLUMN_KEY}.right_column_content[${index}].rich_text`} />
                )}
              </CorThreeColumnTextTile>
            ))
          : null}
      </div>
    </div>
  );
};

export const CorThreeColumn: FC = () => {
  const { getContentByKey } = useContent();
  const { container_background_color }: ICSThreeColumnData = getContentByKey(BASE_KEY, {});
  const {
    left_column_content,
    middle_column_content,
    right_column_content,
    button_row,
    style,
  }: ICSThreeColumnBlockData = getContentByKey(BASE_COLUMN_KEY, {});
  const backgroundColor = container_background_color?.replace(' ', '-')?.toLowerCase() || 'white';
  const buttons = button_row?.[0]?.button || [];
  const buttonsLabel = button_row?.[0]?.row_label || '';

  if (
    !left_column_content?.length &&
    !middle_column_content?.length &&
    !right_column_content?.length &&
    !buttons.length
  ) {
    return null;
  }

  return (
    <div className={classNames('cor-three-column', `cor-three-column--${backgroundColor}`)}>
      <div className="cor-three-column__wrapper grid-container">
        {left_column_content?.length ? (
          <div
            data-testid={'cor-three-column__column-left'}
            className={classNames('cor-three-column__column-left', `is-${style}`)}
          >
            {left_column_content.map((_, index) => (
              <div key={index.toString()} className="cor-three-column__title">
                <CorContentstackHtml contentKey={`${BASE_COLUMN_KEY}.left_column_content[${index}].rich_text`} />
              </div>
            ))}
          </div>
        ) : null}

        {style === 'tiles' && (
          <RenderTiles right_column_content={right_column_content} middle_column_content={middle_column_content} />
        )}

        {style === 'columns' && (
          <RenderColumns right_column_content={right_column_content} middle_column_content={middle_column_content} />
        )}

        {buttons?.length ? (
          <div data-testid={'cor-three-column__button-row'} className={'cor-three-column__button-row'}>
            {buttonsLabel && (
              <div className={'cor-three-column__button-row-label'}>
                <CorTypography variant={'small-caps'} component={'span'} color={'dark-gray'}>
                  {buttonsLabel}
                </CorTypography>
              </div>
            )}
            <div className="cor-three-column__buttons-list">
              {buttons.map((button, index) => {
                const asset = corContentstackAssetLinkResolver(button)?.url;
                return (
                  <CorButtonLink
                    className={'cor-three-column__button'}
                    color={(button?.button_style || 'primary').toLowerCase()}
                    href={asset ?? button?.button?.href}
                    target={button?.open_in_a_new_tab ? '_blank' : '_self'}
                    iconUrl={get(button, 'button_icon[0].cdn_url')}
                    iconPosition={(button?.button_alignment as string) || 'left'}
                    key={`btn-link-${index}`}
                    asset={asset}
                  >
                    {button?.button?.title}
                  </CorButtonLink>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
