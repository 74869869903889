import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { IBluesnap } from 'types/bluesnap';
import { store } from './store';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { clearCachedContentstackContent } from './utils/clear-cached-contentstack-content';
import './index.scss';

const container = document.getElementById('root') as Element;
const root = createRoot(container);

declare global {
  const bluesnap: IBluesnap;
}

clearCachedContentstackContent();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
