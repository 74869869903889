import { FC } from 'react';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { IUserLocation } from 'features/my-account-menu';
import { CorButton } from 'components/cor-button';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import './locations-select-confirmation-modal.scss';
import { ILocation } from 'components/layout/cor-header/components/utility-navigation/utility-nav-user-submenu/utility-nav-user-submenu';

export interface ILocationsSelectConfirmationModalProps extends IModalProps {
  preselectedLocation: IUserLocation | ILocation;
  onLocationSubmit: () => void;
}

export const LocationsSelectConfirmationModal: FC<ILocationsSelectConfirmationModalProps> = ({
  isOpen,
  onClose,
  preselectedLocation,
  onLocationSubmit,
}) => {
  const contentstackPath = 'modals.0.location_change_confirmation_modal.0';

  return (
    <Modal className="locations-select-confirmation-modal" isOpen={isOpen} onClose={onClose}>
      <ModalHeader className="locations-select-confirmation-modal__heading">
        <ContentstackText contentKey={`${contentstackPath}.heading`} />
      </ModalHeader>
      <p className="locations-select-confirmation-modal__message">
        <ContentstackMessage
          type="notifications"
          messageId="MSG020"
          interpolateParams={{ location: preselectedLocation?.label }}
        />
      </p>
      <ModalFooter className="locations-select-confirmation-modal__actions" contentstackPath={contentstackPath}>
        <CorButton className="text" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.cancel_button`} />
        </CorButton>
        <CorButton className="locations-select-confirmation-modal__confirm-button" onClick={onLocationSubmit}>
          <ContentstackText contentKey={`${contentstackPath}.confirm_button`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
