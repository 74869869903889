import { stringify } from 'qs';
import { IAlgoliaProductsSuggestionsItem } from 'types/algolia';
import { Routes } from 'constants/routes.enum';

export function transformItem(item: IAlgoliaProductsSuggestionsItem, isAuthenticated: boolean) {
  const itemUrl =
    item.url ||
    (isAuthenticated
      ? `${Routes.ProductDetailsPage}/${item.productCtKey}?${stringify({ selectedSku: item.sku })}`
      : `${Routes.ProductSearchPage}?${stringify({ query: item.sellingName })}`);

  return {
    ...item,
    itemName: item.title || item.sellingName,
    itemUrl,
  };
}
