import './cor-footer-desktop.scss';

import React from 'react';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import { ContentstackImage } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import { CorFooterNewsletter } from '../cor-footer/components';
import { CorFooterLegal, CorFooterMenuLinks, CorFooterShopProducts } from './components';

const LOGO_KEY = 'footer_logo_group.image';
const SHOP_PRODUCTS_CTA_KEY = 'shop_products_cta';
const ACCOUNT_CTA_KEY = 'account_cta';
export interface ILogo {
  opens_in_a_new_tab?: boolean;
}

export interface IShopProductsCTA {
  include_account_cta?: boolean;
  include_shop_cta?: boolean;
}
export interface IAccountCTA {
  include_account_cta?: boolean;
}

const CorFooterDesktop = ({ onLogout = (e: React.SyntheticEvent<HTMLAnchorElement>) => {} }) => {
  const { getContentByKey } = useContent();
  const logo: ILogo = getContentByKey(LOGO_KEY, {});
  const accountCTA: IAccountCTA = getContentByKey(ACCOUNT_CTA_KEY, {});
  const shopProductsCTA: IShopProductsCTA = getContentByKey(SHOP_PRODUCTS_CTA_KEY, {});

  return (
    <div className="cor-footer-desktop">
      <div className="grid-container cor-footer-desktop__main">
        <div className="main__newsletter">
          <CorFooterNewsletter />
          <CorContentstackLink
            fallbackElement="div"
            contentKey="footer_logo_group.image.link_url"
            className="logo"
            opensInNewTab={!!logo?.opens_in_a_new_tab}
          >
            <ContentstackImage contentKey="footer_logo_group.image.image" />
          </CorContentstackLink>
        </div>
        <CorFooterMenuLinks />
        {(accountCTA?.include_account_cta || shopProductsCTA?.include_shop_cta) && (
          <CorFooterShopProducts onLogout={onLogout} />
        )}
      </div>

      <CorFooterLegal />
    </div>
  );
};

export default React.memo(CorFooterDesktop);
