import qs from 'qs';
import { useHistory } from 'react-router-dom';

export const useQueryString = (searchQuery: string) => {
  const history = useHistory();

  let searchParams = qs.parse(searchQuery, { ignoreQueryPrefix: true });

  const replaceHistorySearch = (searchParams: string) => {
    const newSearch = qs.stringify(searchParams);
    history.push({
      ...history.location,
      search: `?${newSearch}`,
    });
  };

  return [searchParams, replaceHistorySearch];
};
