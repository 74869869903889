import { FC, useCallback } from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  MultiValue,
  OptionProps,
  SingleValue,
  ValueContainerProps,
} from 'react-select';
import { FieldProps } from 'formik';
import classNames from 'classnames';
import { ILinkOptionType, IOptionType } from 'components/custom-dropdown';

import './select-field.scss';

interface ISelectFieldProps extends FieldProps {
  onChange: (option: MultiValue<IOptionType> | SingleValue<IOptionType>) => void;
  onBlur: () => void;
  options: IOptionType[] | ILinkOptionType[];
  value: string;
  className?: string;
  name: string;
  dropdownIcon?: string;
  renderOption?: (props: OptionProps<IOptionType>) => JSX.Element;
  disabled: boolean;
}

export const SelectField: FC<ISelectFieldProps> = ({
  options,
  field,
  onChange,
  onBlur,
  value,
  form: { setFieldValue, setFieldTouched },
  className,
  name,
  dropdownIcon,
  renderOption,
  disabled = false,
}) => {
  const defaultValue = (options: IOptionType[] | ILinkOptionType[], value: string) => {
    return options ? options.find((option: IOptionType | ILinkOptionType) => option.value === value) : '';
  };

  const onChangeHandler = (option: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
    setFieldValue(field.name, option);
    if (onChange) {
      onChange(option);
    }
  };

  const onBlurHandler = () => {
    if (onBlur) {
      onBlur();
    }
    setFieldTouched(field.name);
  };
  const selectClass = classNames('select-field', className);

  const DropdownIndicator = (props: DropdownIndicatorProps<IOptionType>) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <span className="select-field__dropdown-icon" />
        </components.DropdownIndicator>
      )
    );
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps<IOptionType>) => {
    const CustomValueContainer = useCallback(
      () => (
        <>
          <div className="select-field__icon">
            <img src={dropdownIcon} alt="dropdown icon" />
          </div>
          <components.ValueContainer {...props}>{children}</components.ValueContainer>
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return dropdownIcon ? (
      <CustomValueContainer />
    ) : (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  };

  const Option = ({ children, ...props }: OptionProps<IOptionType>) => {
    return renderOption ? (
      renderOption({ children, ...props })
    ) : (
      <components.Option {...props}>{children}</components.Option>
    );
  };

  return (
    <Select
      {...field}
      components={{ DropdownIndicator, ValueContainer, Option }}
      value={defaultValue(options, value) as IOptionType}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      name={name}
      classNamePrefix="select-field"
      options={options}
      className={selectClass}
      isDisabled={disabled}
    />
  );
};
