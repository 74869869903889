import { combineReducers } from 'redux';
import { last, isEqual } from 'lodash-es';
import { mergeSelectedFacets, mergeFacetsFromResponse, updateSelectedFacetsOnDawnBrandsReset } from 'utils/facet-utils';
import { Actions } from 'constants/actions.enum';
import { ISortParams } from 'types/product-sort-options';
import { ICatalogProduct } from 'types/catalog';
import { ICarouselProduct } from 'types/product';
import { ILineItem } from 'types/line-item';
import { IFacet, ISelectedFacet } from 'types/facets';
import { Limit } from 'constants/limit.enum';
import { createReducer } from 'store/reducer-creator';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import * as actions from './actions';
import types from './action-types';

const initialSelectedFacets = [];

export interface IMeta {
  offset: number;
  limit: Limit;
  total: number;
  sortOptions: ISortParams;
  totalSkus?: number;
  isLoading: boolean;
  expandedFacet: IExpandedFacet;
  queryAfterRemoval?: string;
}

export interface ICategoryBreadcrumb {
  key: string;
  name: string;
}

export interface IExpandedFacet {
  key: string;
  isExpanded: boolean;
}

export interface ICategory {
  categoryKey: string;
  categoryName: string;
  breadcrumbs: ICategoryBreadcrumb[];
  children: ICategory[];
  count: number;
}

export interface IFullCategoriesTreeState {
  categories: ICategory[];
  loading: boolean;
}

export interface IProductsState {
  items: ICatalogProduct[];
  meta: IMeta;
  facets: IFacet[];
  fullCategoriesTree: IFullCategoriesTreeState;
  dawnBrands: string[];
  taxonomyPath: ICategoryBreadcrumb[];
  selectedCategory: ICategoryBreadcrumb | null;
  selectedFacets: ISelectedFacet[];
  temporarySelectedCategory: ICategoryBreadcrumb | null;
  temporaryFacets: IFacet[];
  temporarySelectedFacets: ISelectedFacet[];
  carouselProducts: ICarouselProduct[];
  mustHaveProducts: ICarouselProduct[];
}

const expandedFacetIniialState: IExpandedFacet = { key: '', isExpanded: false };

const metaInitial = {
  offset: 0,
  limit: Limit.TwentyFour,
  total: 0,
  sortOptions: {},
  totalSkus: 0,
  isLoading: false,
  expandedFacet: expandedFacetIniialState,
  queryAfterRemoval: '',
};

const initialFullCategoriesTreeState = {
  categories: [],
  loading: false,
};

export const productsInitialState: IProductsState = {
  items: [],
  meta: metaInitial,
  dawnBrands: [],
  taxonomyPath: [],
  selectedCategory: null,
  facets: [],
  fullCategoriesTree: initialFullCategoriesTreeState,
  selectedFacets: initialSelectedFacets,
  temporarySelectedCategory: null,
  temporaryFacets: [],
  temporarySelectedFacets: [],
  carouselProducts: [],
  mustHaveProducts: [],
};

const items = createReducer<ICatalogProduct[]>(
  {
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => [
      ...plpProducts.result,
    ],
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.ctProductKey === product.productKey),
      })),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.ctProductKey === product.productKey),
      })),
  },
  []
);

const meta = createReducer<IMeta>(
  {
    [actions.getProductsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => ({
      ...state,
      total: plpProducts.total,
      offset: plpProducts.offset,
      limit: plpProducts.limit,
      totalSkus: plpProducts.totalSkus,
      queryAfterRemoval: plpProducts.queryAfterRemoval,
      isLoading: false,
    }),
    [types.PRODUCTS_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
    [types.SET_PRODUCTS_SORT_PARAMS]: (state, { payload }) => ({
      ...state,
      sortOptions: payload.sortParams,
      offset: metaInitial.offset,
    }),
    [types.SET_PRODUCTS_LIMIT]: (state, { payload }) => ({
      ...state,
      limit: payload.limit,
      offset: metaInitial.offset,
    }),
    [types.SET_SELECTED_FACETS]: (state) => ({
      ...state,
      offset: metaInitial.offset,
    }),
    [types.RESET_DAWN_BRANDS]: (state) => ({
      ...state,
      offset: metaInitial.offset,
    }),
    [types.UPDATE_SELECTED_CATEGORY_KEY]: (state) => ({
      ...state,
      offset: metaInitial.offset,
      limit: metaInitial.limit,
      sortOptions: metaInitial.sortOptions,
    }),
    [types.RESET_SELECTED_FACETS]: (state) => ({
      ...state,
      offset: metaInitial.offset,
    }),
    [types.CLEAR_SELECTED_FACETS]: (state) => ({
      ...state,
      offset: metaInitial.offset,
    }),
    [types.SET_SELECTED_FILTERS]: (state, { payload: { meta } }) => meta,
    [types.CLEAR_PRODUCTS_META_STATE]: () => metaInitial,
    [types.SET_EXPANDED_FACET]: (state, { payload }) => ({
      ...state,
      expandedFacet: payload.expandedFacet,
    }),
  },
  metaInitial
);

const facets = createReducer<IFacet[]>(
  {
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => plpProducts.facets,
    [types.SET_SELECTED_FILTERS]: (state, { payload }) => [...payload.facets],
  },
  []
);

const fullCategoriesTree = createReducer<IFullCategoriesTreeState>(
  {
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (
      state,
      {
        payload: {
          plpProducts: { fullCategoriesTree },
        },
      }
    ) => ({
      ...state,
      categories: fullCategoriesTree.categories,
    }),
  },
  initialFullCategoriesTreeState
);

const dawnBrands = createReducer<string[]>(
  {
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (
      state,
      {
        payload: {
          plpProducts: { dawnBrands },
        },
      }
    ) => {
      if (dawnBrands && !isEqual(state, dawnBrands)) {
        return dawnBrands;
      }
      return state;
    },
  },
  []
);

const selectedFacets = createReducer<ISelectedFacet[]>(
  {
    [types.SET_SELECTED_FILTERS]: (state, { payload: { selectedFacets } }) => selectedFacets,
    [types.SET_SELECTED_FACETS]: (state, { payload }) => {
      return mergeSelectedFacets(state, payload.selectedFacets);
    },
    [types.PRESELECT_SELECTED_FACETS_FROM_URL]: (state, { payload }) => {
      return mergeSelectedFacets([], payload.preselectedFacets);
    },
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => {
      return mergeFacetsFromResponse(state, plpProducts.facets);
    },
    [types.UPDATE_SELECTED_CATEGORY_KEY]: () => initialSelectedFacets,
    [types.RESET_DAWN_BRANDS]: (state, { payload: { dawnBrands } }) => {
      return updateSelectedFacetsOnDawnBrandsReset(state, dawnBrands);
    },
    [types.RESET_SELECTED_FACETS]: () => initialSelectedFacets,
    [types.CLEAR_SELECTED_FACETS]: () => [],
  },
  initialSelectedFacets
);

const taxonomyPath = createReducer<ICategoryBreadcrumb[]>(
  {
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      plpProducts.taxonomy,
  },
  []
);

const selectedCategory = createReducer<ICategoryBreadcrumb | null>(
  {
    [actions.getProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      last(plpProducts.taxonomy) || null,
    [types.SET_SELECTED_FILTERS]: (state, { payload: { selectedCategory } }) => selectedCategory,
    [types.SET_SELECTED_CATEGORY_KEY]: (state, action) => ({
      key: action.payload,
      name: '',
    }),
    [types.UPDATE_SELECTED_CATEGORY_KEY]: (state, action) => ({
      name: state?.name || '',
      key: action.payload,
    }),
  },
  null
);

const temporarySelectedCategory = createReducer<ICategoryBreadcrumb | null>(
  {
    [types.SET_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { selectedCategory } }) => selectedCategory,
    [types.RESET_TEMPORARY_SELECTED_FILTERS]: () => null,
    [types.SET_TEMPORARY_SELECTED_CATEGORY]: (state, { payload }) => payload,
  },
  null
);
const temporaryFacets = createReducer<IFacet[]>(
  {
    [types.SET_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { facets } }) => facets,
    [types.RESET_TEMPORARY_SELECTED_FILTERS]: () => [],
    [actions.getTemporaryFiltersInfoActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      plpProducts.facets,
  },
  []
);

const temporarySelectedFacets = createReducer<ISelectedFacet[]>(
  {
    [types.SET_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { selectedFacets } }) => selectedFacets,
    [types.RESET_TEMPORARY_SELECTED_FILTERS]: () => initialSelectedFacets,
    [types.SET_TEMPORARY_SELECTED_CATEGORY]: () => initialSelectedFacets,
    [types.CLEAR_TEMPORARY_SELECTED_FACETS]: () => [],
    [types.SET_TEMPORARY_SELECTED_FACETS]: (state, { payload }) => mergeSelectedFacets(state, payload.selectedFacets),
    [actions.getTemporaryFiltersInfoActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => {
      return mergeFacetsFromResponse(state, plpProducts.facets);
    },
  },
  initialSelectedFacets
);

const carouselProducts = createReducer<ICarouselProduct[]>(
  {
    [actions.getCarouselProductsActionConstants[Actions.SUCCESS]]: (state, { payload }) => [...payload],
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
  },
  []
);

const mustHaveProducts = createReducer<ICarouselProduct[]>(
  {
    [actions.getProductsBySkusActionConstants[Actions.SUCCESS]]: (state, { payload }) => [...payload],
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
  },
  []
);

export default combineReducers({
  items,
  meta,
  dawnBrands,
  taxonomyPath,
  selectedCategory,
  facets,
  fullCategoriesTree,
  selectedFacets,
  temporarySelectedCategory,
  temporaryFacets,
  temporarySelectedFacets,
  carouselProducts,
  mustHaveProducts,
});
