import { pick } from 'lodash-es';
import { ISessionParamsPayload } from 'store/customer-login/sagas/get-session-params';

export const keysToPreload = ['nextOrderDueDate'];

export const flush = () => {
  keysToPreload.forEach((key) => window.localStorage.removeItem(key));
};

export const getPreloadedParams = () => {
  return pick(window.localStorage, keysToPreload);
};

export const cacheAll = (payload: ISessionParamsPayload) => {
  keysToPreload.forEach((key) => {
    window.localStorage.setItem(key, payload[key]);
  });
};

export const cacheNextDueDate = (nextOrderDueDate: string) => {
  window.localStorage.setItem('nextOrderDueDate', nextOrderDueDate);
};
