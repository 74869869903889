import { useDispatch } from 'react-redux';
import {
  setShoppingListNameDuplicateError,
  createShoppingListWithProducts,
  createEmptyShoppingList,
  getAllShoppingLists,
} from 'store/shopping-lists/actions';
import { ContentstackMessage } from 'components/contentstack/contentstack-message';
import {
  useCreateShoppingListSuccessMessageModal,
  useSuccessErrorMessageModal,
  useHideGlobalModalHandler,
} from 'hooks/use-global-modal';
import { getHomeShoppingLists } from 'store/home/actions';
import { IMultipleLineItem } from 'store/shopping-cart/sagas/add-multiple-products-to-cart';
import { ISku } from 'types/product';
import { IDeactivatedLineItemDetails } from 'types/order-details';

export interface ICreateListSuccessCallBackPrams {
  shoppingListName: string;
  id?: string;
  addedItemsCounter?: number;
  deactivatedLineItems?: IDeactivatedLineItemDetails[];
}
export interface ISuccessMessageModalOtions {
  onListNameClick: () => void;
}
interface IUseCreateListProps {
  successMessageModalOtions?: ISuccessMessageModalOtions;
  createHandlerLineItems?: IMultipleLineItem[];
  createListFailCallBack?: (deactivatedLineItems: string[], status: number) => void;
  createListSuccessCallBack?: ({
    shoppingListName,
    id,
    addedItemsCounter,
    deactivatedLineItems,
  }: ICreateListSuccessCallBackPrams) => void;
  defaultFailCallBack?: () => void;
}

export const useCreateList = (props: IUseCreateListProps) => {
  const {
    successMessageModalOtions = {},
    createHandlerLineItems,
    createListFailCallBack,
    createListSuccessCallBack,
    defaultFailCallBack,
  } = props;

  const dispatch = useDispatch();
  const hideGlobalModal = useHideGlobalModalHandler();
  const showCreateShoppingListSuccessMessageModal = useCreateShoppingListSuccessMessageModal(successMessageModalOtions);

  const showItemsAmountExceededErrorModal = useSuccessErrorMessageModal({
    type: 'success',
    messageId: 'MSG152',
    children: <ContentstackMessage type="error" messageId="MSG153" />,
  });

  const showFailedAddItemsErrorModal = useSuccessErrorMessageModal({
    type: 'error',
    messageId: 'MSG153',
    autoClose: false,
    hasCrossButton: true,
    children: <ContentstackMessage type="error" messageId="MSG154" />,
  });

  const listFailMapper = {
    409: () => {
      dispatch(setShoppingListNameDuplicateError(true));
    },
    product_409: () => {
      dispatch(setShoppingListNameDuplicateError(true));
    },
    422: () => {
      showItemsAmountExceededErrorModal();
    },
    product_422: () => {
      showFailedAddItemsErrorModal();
    },
    default: () => {
      hideGlobalModal();
    },
    product_default: () => {
      defaultFailCallBack && defaultFailCallBack();
    },
  };

  const createListFail = (status: number) => {
    (listFailMapper[status] || listFailMapper.default)();
  };

  const createProductListFailCallBack = (status: number) => {
    (listFailMapper[`product_${status}`] || listFailMapper['product_default'])();
    dispatch(getAllShoppingLists.request());
  };

  const createListSuccess = (name: string) => {
    dispatch(getHomeShoppingLists.request());
    showCreateShoppingListSuccessMessageModal({
      listName: name,
    });
  };

  const createListHandler = (values: { shoppingListName: string }, selectedSku?: ISku) => {
    dispatch(
      createShoppingListWithProducts.request({
        lineItems: createHandlerLineItems,
        name: values.shoppingListName,
        onSuccessCallback: createListSuccessCallBack || createListSuccess,
        onFailCallback: createListFailCallBack || createProductListFailCallBack,
      })
    );
  };

  const createEmptyListHandler = ({ shoppingListName }: { shoppingListName: string }) => {
    dispatch(
      createEmptyShoppingList.request({
        name: shoppingListName,
        onSuccessCallback: createListSuccessCallBack || createListSuccess,
        onFailCallback: createListFailCallBack || createListFail,
      })
    );
  };

  return {
    createListHandler,
    createEmptyListHandler,
    showFailedAddItemsErrorModal,
    showItemsAmountExceededErrorModal,
    showCreateShoppingListSuccessMessageModal,
    hideGlobalModal,
  };
};
