import React, { FC, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { COR_DAWN_ORANGE } from 'corporate-ui/cor-constants';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';
import './back-to-top.scss';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';

export interface IBackToTopProps {
  showOnAllBreakpoints?: boolean;
}

export const BackToTop: FC<IBackToTopProps> = ({ showOnAllBreakpoints = false }) => {
  const [showButton, setShowButton] = useState<boolean>(false);

  const { isMobile } = useBreakpoint();
  const { getContentByKey } = useContent();
  const displayBackToTop: boolean = getContentByKey('page_configuration.display_back_to_top', true);
  const ref = useRef<CSSTransition>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const scrollOffset = 300;

  useEffect(() => {
    const handlePageScroll = () => setShowButton(window.pageYOffset > scrollOffset);
    window.addEventListener('scroll', handlePageScroll);
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  }, []);

  return displayBackToTop && (isMobile || showOnAllBreakpoints) ? (
    <CSSTransition timeout={0} in={showButton} ref={ref} unmountOnExit>
      <button
        ref={buttonRef}
        title="Back to the top"
        className="back-to-top"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <IconMenuArrow dir="down" color={COR_DAWN_ORANGE} />
        <span>Top</span>
      </button>
    </CSSTransition>
  ) : (
    <></>
  );
};
