import React from 'react';
import { template } from 'lodash-es';
import classNames from 'classnames';
import { COR_PAGINATION_PAGE_RANGE_TO_DISPLAY } from 'corporate-constants/pagination.constants';
import { CorSmallCardTitleCategory } from 'corporate-components/cor-cards';
import { useAlgolia, useAlgoliaQueryParams } from 'hooks';
import { parseAlgoliaPagination } from 'corporate-utils/cor-algolia-helpers';
import { CorTypography } from 'corporate-ui';
import { IAlgoliaContentTypesEnum, IAlgoliaTagsEnum } from 'queries/use-algolia-query';
import { ICSSearchResultFilterButtons } from 'corporate-interfaces';
import { LoadingIndicator } from 'components/loading-indicator';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { Pagination } from 'components/pagination';
import { ContentstackContext } from 'context/contentstack';
import { CorSearchResultsEmpty } from '../cor-search-results-empty';
import { SearchResultsContentTypeComponentsMap, SearchResultsTagsComponentsMap } from '../../search-results.mapper';
import {
  SearchResultsContentTypeCardsClassNameMap,
  SearchResultsTagsCardsClassNameMap,
} from '../../search-results.config';

const LABELS_KEY = 'labels.0.key_value_pair.value';
const ENTRIES_KEY = 'entries';
const PAGINATION_OFFSET = { mobile: 8, desktop: 12 };
const GLOBAL_FACETS_KEY = 'global_facets.0.page_types';

const CorListPage = () => {
  const { getLabelsByKey, getContentByKey } = useContent();
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const { isMobile } = useBreakpoint();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});
  const contentStackPageTypes = getContentByKey<ICSSearchResultFilterButtons>(GLOBAL_FACETS_KEY, []);

  const pageOffset = React.useMemo(() => (isMobile ? PAGINATION_OFFSET.mobile : PAGINATION_OFFSET.desktop), [isMobile]);

  const algoliaQuery = useAlgolia({
    pageType: queryParams?.page_type || undefined,
    pageOffset,
    page: queryParams?.page || ALGOLIA_DEFAULT_PAGE_NUMBER,
    query: queryParams?.query || '',
  });

  const algoliaUnfilteredQuery = useAlgolia({
    pageOffset,
    page: queryParams?.page || ALGOLIA_DEFAULT_PAGE_NUMBER,
    query: queryParams?.query || '',
    isTotalShopProductsCounted: true,
  });

  const algoliaUnfilteredMetaData = algoliaUnfilteredQuery?.data?.globalPageTypeMeta;
  const algoliaMetaData = algoliaQuery?.data?.globalPageTypeMeta;
  const algoliaData = algoliaQuery?.data?.globalPageTypeContents?.[0];

  const categoryName = (algoliaData?.categories?.[0].categoryName || '') as IAlgoliaContentTypesEnum;
  const categoryTitle = algoliaData?.title || '';
  const categorytag =
    contentStackPageTypes?.find(({ type, title }) => [categoryName].includes(type) && [categoryTitle].includes(title))
      ?.tag ?? (({ FAQ: '', CorporateProducts: '' } as unknown) as IAlgoliaTagsEnum);

  const categoryNameLabel = contentStackPageTypes?.find(
    ({ type, title }) => [categoryName].includes(type) && [categoryTitle].includes(title)
  )?.title;

  const handlePageChange = React.useCallback(
    (selectedItem: { selected: number }) => {
      setQueryParams({ page: selectedItem?.selected + 1 });
    },
    [setQueryParams]
  );

  const entries = algoliaData?.c_cards;

  const Card =
    ([IAlgoliaContentTypesEnum.StandardPage].includes(categoryName)
      ? SearchResultsTagsComponentsMap.get(categorytag)
      : SearchResultsContentTypeComponentsMap.get(categoryName)) || CorSmallCardTitleCategory;

  if (algoliaQuery.isLoading) return <LoadingIndicator />;
  if (algoliaQuery.isError || (algoliaQuery.isSuccess && !algoliaMetaData?.page?.total_results))
    return <CorSearchResultsEmpty />;
  return (
    <>
      {!!algoliaMetaData?.page?.total_results && (
        <section className="grid-container search-results-page__container">
          <CorTypography variant="small-caps">
            {template(labels?.pagination_total_results || '<%= total %> TOTAL RESULTS!!!')(
              parseAlgoliaPagination(algoliaUnfilteredMetaData)
            )}
          </CorTypography>
        </section>
      )}

      <section className="search-results-page__cards-container search-results-page__cards-container--first">
        <div className="grid-container search-results-page__container search-results-page__cards-header">
          <CorTypography variant="h2">
            {!!queryParams?.query ? (
              <>
                <span>{queryParams.query}</span> {labels?.page_type_in || 'in'} {categoryNameLabel}
              </>
            ) : (
              categoryNameLabel
            )}
          </CorTypography>
          <CorTypography variant="body-large" color="mid-gray" className="search-results-page__cards-results">
            {template(labels?.list_title || '<%= total %> <%= category_name_label %> Results')({
              ...parseAlgoliaPagination(algoliaMetaData),
              category_name_label: categoryNameLabel,
            })}
          </CorTypography>
        </div>

        <ContentstackContext.Provider value={{ entries }}>
          <div
            className={classNames(
              'grid-container',
              'search-results-page__container',
              'search-results-page__cards',
              'search-results-page__cards--list-page',
              {
                [SearchResultsContentTypeCardsClassNameMap.get(categoryName) || '']:
                  ![IAlgoliaContentTypesEnum.StandardPage].includes(categoryName) &&
                  SearchResultsContentTypeCardsClassNameMap.has(categoryName),
              },
              {
                [SearchResultsTagsCardsClassNameMap.get(categorytag) || '']:
                  [IAlgoliaContentTypesEnum.StandardPage].includes(categoryName) &&
                  SearchResultsTagsCardsClassNameMap.has(categorytag),
              }
            )}
          >
            {entries?.map((_, index) => (
              <Card contentKeyBase={`${ENTRIES_KEY}[${index}]`} key={index.toString()} />
            ))}
          </div>
        </ContentstackContext.Provider>
      </section>

      {(algoliaMetaData?.page?.total_pages || 0) > 1 && (
        <ContentstackContext.Provider
          value={{
            labels: {
              pagination: {
                previous_button_label: labels?.pagination_back || 'Back',
                next_button_label: labels?.pagination_next || 'Next',
              },
            },
          }}
        >
          <section className="grid-container search-results-page__pagination">
            <Pagination
              currentPage={(queryParams?.page || 0) - 1}
              pagesTotal={algoliaMetaData?.page?.total_pages || queryParams?.page || 0}
              contentstackBasePath="labels"
              onPageChange={handlePageChange}
              pageRangeToDisplay={COR_PAGINATION_PAGE_RANGE_TO_DISPLAY}
            />
          </section>
        </ContentstackContext.Provider>
      )}
    </>
  );
};

export default React.memo(CorListPage);
