import { FC, SyntheticEvent } from 'react';
import { Formik } from 'formik';
import { omit } from 'lodash-es';
import { Modal } from 'components/modals/common/modal';
import { TextField } from 'components/forms/text-field';
import { FormWithValidationOnSubmit, IFormState } from 'components/forms/form-with-validation-on-submit';
import { ErrorNotification } from 'components/forms/error-notification';
import { PageLeaveConfirmationModal } from 'components/page-leave-confirmation-modal';
import { ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import { CorButton } from 'components/cor-button';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './edit-shopping-list-name-modal.scss';

const PATH_TO_MODAL = 'modals.0.edit_shopping_list_name_modal.0';
const PATH_TO_FORM = `${PATH_TO_MODAL}.edit_shopping_list_name_form[0]`;
const PATH_TO_VALIDATION_MESSAGES = `${PATH_TO_FORM}.validation_messages`;

interface IShoppingListNameFormValues {
  listName: string;
}

export interface IEditShoppingListNameModalProps extends IShoppingListNameFormValues {
  isOpen: boolean;
  onSave: ({ listName }: IShoppingListNameFormValues) => void;
  onClose: () => void;
  listName: string;
  isShoppingListNameDuplicated: boolean;
  setShoppingListNameDuplicatedHandler: (isShoppingListNameDuplicated: boolean) => void;
  pathToContents?: string;
}

export const EditShoppingListNameModal: FC<IEditShoppingListNameModalProps> = ({
  isOpen,
  onClose,
  onSave,
  listName,
  isShoppingListNameDuplicated,
  setShoppingListNameDuplicatedHandler,
}) => {
  const { getContentByKey } = useContent();

  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const closeModalHandler = (dataWasChanged: boolean, resetForm: () => void) => {
    if (dataWasChanged) {
      const closingIsAllowed = window.confirm(leavingPageModalText);

      if (closingIsAllowed) {
        resetForm();
        setShoppingListNameDuplicatedHandler(false);
        onClose();
      }
      return;
    }

    setShoppingListNameDuplicatedHandler(false);
    onClose();
  };

  const formContainsEmptyRequiredFields = (formValues: IShoppingListNameFormValues) => {
    return Object.values(formValues).some((value) => value === '');
  };

  return (
    <div className="edit-shopping-list-name-modal">
      <Formik validateOnChange={false} initialValues={{ listName }} enableReinitialize={true} onSubmit={onSave}>
        {({ dirty, isValid, handleSubmit, values, setErrors, errors, resetForm, setFieldValue }) => (
          <FormWithValidationOnSubmit<IShoppingListNameFormValues>
            className="edit-shopping-list-name-modal__form"
            onChange={(event) => {
              const fieldName = (event.target as HTMLInputElement).name;
              setErrors(omit(errors, fieldName));
            }}
            {...{ handleSubmit, isValid, dirty }}
          >
            {({ invalidFormIsSubmitted }: IFormState) => {
              return (
                <>
                  <Modal
                    className="edit-shopping-list-name-modal__content"
                    isOpen={isOpen}
                    onClose={() => closeModalHandler(dirty, resetForm)}
                    size="medium"
                  >
                    <ModalHeader titleContentstackPath={`${PATH_TO_MODAL}.heading`} />
                    <div className="edit-shopping-list-name-modal">
                      {isShoppingListNameDuplicated && (
                        <ErrorNotification
                          className="edit-shopping-list-name-modal__general-error"
                          message={getContentByKey<string>(
                            `${PATH_TO_MODAL}.shopping_list_name_uniqueness_error[0].text`,
                            ''
                          )}
                        />
                      )}
                      {invalidFormIsSubmitted && (
                        <ErrorNotification
                          className="edit-shopping-list-name-modal__general-error"
                          message={getContentByKey<string>(`${PATH_TO_VALIDATION_MESSAGES}.general`, '')}
                        />
                      )}
                      <div className="edit-shopping-list-name-modal__form-fields">
                        <TextField
                          className="edit-shopping-list-name-modal__field"
                          id="listName"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.name_field_label`, '')}
                          maxLength={35}
                          name="listName"
                          onChange={(e: SyntheticEvent) => {
                            setFieldValue('listName', (e.target as HTMLInputElement).value);
                            if (setShoppingListNameDuplicatedHandler) {
                              setShoppingListNameDuplicatedHandler(false);
                            }
                          }}
                          highlight={isShoppingListNameDuplicated}
                        />
                      </div>
                    </div>
                    <ModalFooter
                      contentstackPath={PATH_TO_FORM}
                      cancelButtonHandler={() => closeModalHandler(dirty, resetForm)}
                      hasCancelButton
                    >
                      <CorButton
                        color="primary edit-shopping-list-name-modal__save-button"
                        type="submit"
                        disabled={formContainsEmptyRequiredFields(values) || !dirty}
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.save_button_title`} />
                      </CorButton>
                    </ModalFooter>
                    {dirty && <PageLeaveConfirmationModal />}
                  </Modal>
                </>
              );
            }}
          </FormWithValidationOnSubmit>
        )}
      </Formik>
    </div>
  );
};
