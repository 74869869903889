import config from 'config/app';
import { CheckoutSteps } from 'types/checkout';
import { ILineItem } from 'types/order-details';
import { ICatalogProduct } from 'types/catalog';
import { IGALineItem, IGAPurchase } from 'types/g-analitics';
import { IDataLayer } from 'types/data-layer';
import { GAProductActions, IGAImpression, IGAProduct } from './types';
import { flush as flushLocalStorageParams, getPreloadedParams, keysToPreload } from './cache-preload';

let hasUserIdBeenSent = false;
let hasInternalUserRoleBeenSent = false;
let hasDeletedLocationStatusBeenSent = false;
let havePreloadValuesBeenSent = false;

declare global {
  const dataLayer: Array<IDataLayer>;
}

export const push = (...args: IDataLayer[]) => {
  dataLayer.push(...args);
};

const sendDcInformation = (dcName, dcNumber, soldToDcNumber, soldToDcName) => {
  push({ dcName });
  push({ dcNumber });
  push({ soldToDcNumber });
  push({ soldToDcName });
};

export const initializeGTM = ({
  userId,
  dcName,
  dcNumber,
  soldToDcNumber,
  soldToDcName,
  shipToNumber: locationExternalId,
}) => {
  if (!hasUserIdBeenSent) {
    push({ userId });
    sendDcInformation(dcName, dcNumber, soldToDcNumber, soldToDcName);
    hasUserIdBeenSent = true;
  }

  locationExternalId && setShipToNumber(locationExternalId);
};

export const resetUserIdBeenSentState = () => (hasUserIdBeenSent = false);

export const clearCachedParams = () => {
  if (!havePreloadValuesBeenSent) {
    return;
  }
  flushLocalStorageParams();
  keysToPreload.forEach((key) => {
    dataLayer.push({
      [key]: undefined,
    });
  });
  havePreloadValuesBeenSent = false;
};

export const populateCachedAnalyticsParams = () => {
  if (!havePreloadValuesBeenSent) {
    push(getPreloadedParams());
    havePreloadValuesBeenSent = true;
  }
};

export const setNextDueDate = (nextOrderDueDate: string) => {
  push({ nextOrderDueDate });
};

export const setShipToNumber = (locationNumber: string) => {
  push({ shipToNumber: locationNumber });
};

export const sendEvent = (eventType: GAProductActions | string, params: object) => {
  if (config.isGtmEnabled === 'true') {
    push({ event: eventType, ...params });
  }
};

export const sendCheckoutStep = (step: CheckoutSteps, lineItems: ILineItem[]) => {
  const lineItemToGAProduct = (item: ILineItem): IGAProduct => ({
    id: item.itemNumber,
    name: item.skuName,
    brand: item.brand,
    price: item.price?.amount ? parseFloat(item.price.amount) : undefined,
    quantity: item.quantity || 1,
  });

  sendEvent('checkout', {
    ecommerce: {
      checkout: {
        actionField: { step },
        products: lineItems.map(lineItemToGAProduct),
      },
    },
  });
};

export const sendPageView = (path?: string) => {
  if (!path) throw new Error('sendPageView requires path to be specified');
  sendEvent('pageview', {
    page_path: path,
  });
};

export const sendCardRendered = () => {
  sendEvent('cart_rendered', {});
};

export const sendProductImpression = (impression: IGAImpression) => {
  sendEvent(GAProductActions.ViewItemList, {
    ecommerce: {
      items: [impression],
    },
  });
};

export const sendPurchase = (purchase: IGAPurchase) => {
  const lineItemToGAProduct = (item: IGALineItem): IGAProduct => ({
    id: item.itemNumber,
    name: item.skuName,
    brand: item.brand,
    price: item.price?.amount ? parseFloat(item.price.amount) : undefined,
    quantity: item.quantity || 1,
  });

  sendEvent(GAProductActions.Purchase, {
    ecommerce: {
      purchase: {
        actionField: {
          id: purchase.orderNumber,
          revenue: purchase.priceSummary.total,
          tax: purchase.priceSummary.totalTaxes,
          shipping: purchase.priceSummary.shippingSurcharge,
        },
        products: purchase.lineItems.map(lineItemToGAProduct),
      },
    },
  });
};

export const sendViewItem = (sku: string) => {
  push({
    event: GAProductActions.ViewItem,
    ecommerce: {
      items: [
        {
          item_id: sku,
        },
      ],
    },
  });
};

export const sendAddToCart = (sku: string) => {
  push({
    event: GAProductActions.AddToCart,
    ecommerce: {
      items: [
        {
          item_id: sku,
        },
      ],
    },
  });
};

export const sendViewProductList = (products: ICatalogProduct[]) => {
  push({
    event: 'view_product_list',
    ecommerce: {
      items: products.map((item) => {
        const defaultSku = item?.skus.find(({ isDefault }) => isDefault) || item?.skus[0];
        return { item_id: defaultSku.sku };
      }),
    },
  });
};

export const sendInternalUserRole = (internalUserRole) => {
  if (!hasInternalUserRoleBeenSent) {
    push({ internalUserRole });
    hasInternalUserRoleBeenSent = true;
  }
};

export const resetInternalUserRole = () => {
  if (hasInternalUserRoleBeenSent) {
    push({ internalUserRole: undefined });
    hasInternalUserRoleBeenSent = false;
  }
};

export const sendDeletedLocationStatus = (deletedLocation) => {
  if (!hasDeletedLocationStatusBeenSent) {
    push({ deletedLocation });
    hasDeletedLocationStatusBeenSent = true;
  }
};

export const resetDeletedLocationStatus = () => {
  if (hasDeletedLocationStatusBeenSent) {
    push({ deletedLocation: undefined });
    hasDeletedLocationStatusBeenSent = false;
  }
};
