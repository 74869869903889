import { useRef } from 'react';
import Countdown from 'react-countdown';
import { ContentstackText } from 'components/contentstack';

interface IOrderCutoffNotificationProps {
  timerData: number;
  onComplete?: () => void;
}

export const OrderCutoffTimer = ({ timerData, onComplete }: IOrderCutoffNotificationProps) => {
  const contentStackPath = 'page_content.order_cutoff_notification[0]';
  const startDate = useRef(Date.now());

  const renderTimer = ({ hours, minutes, seconds }) => (
    <ul className="order-cutoff-notification__timer">
      <li className="order-cutoff-notification__list">
        {hours}
        <span>
          {' '}
          <ContentstackText contentKey={`${contentStackPath}.timer.hour`} />
        </span>
      </li>
      <li className="order-cutoff-notification__list">
        {minutes}
        <span>
          {' '}
          <ContentstackText contentKey={`${contentStackPath}.timer.minute`} />
        </span>
      </li>
      <li className="order-cutoff-notification__list">
        {seconds}
        <span>
          {' '}
          <ContentstackText contentKey={`${contentStackPath}.timer.second`} />
        </span>
      </li>
    </ul>
  );

  return <Countdown date={startDate.current + timerData} renderer={renderTimer} onComplete={onComplete} />;
};
