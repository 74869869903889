import './cor-card-list.scss';

import React from 'react';
import classNames from 'classnames';
import { CorTypography } from 'corporate-ui';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { useContent } from 'hooks/use-content';
import { isExternalUrl } from 'utils/is-external-url';
import { ICSImage } from 'types/contentstack';

export interface ICardHeadline {
  href?: string;
  title?: string;
}
export interface ICardListItem {
  description?: string;
  image?: ICSImage;
  headline: ICardHeadline;
}

export interface ICardListContent {
  title?: string;
  sub_title?: string;
  description?: string;
  card_list_item?: ICardListItem[];
}
const BASE_KEY = 'card_list.card_list[0]';
const BACKGROUND_COLOR_KEY = 'card_list.container_background_color';

const CorCardList = () => {
  const { getContentByKey } = useContent();

  const cardListContent = getContentByKey<ICardListContent>(BASE_KEY, {});
  const backgroundColor = getContentByKey<string>(BACKGROUND_COLOR_KEY, '')
    ?.toLowerCase()
    ?.replace(new RegExp(' ', 'g'), '-');

  return (
    <div className={classNames('cor-card-list', { [`cor-bg-color__${backgroundColor}`]: !!backgroundColor })}>
      <div className="grid-container">
        {(!!cardListContent?.title || !!cardListContent?.sub_title) && (
          <div>
            {!!cardListContent?.title && (
              <CorTypography
                variant="h2"
                component="h2"
                className="cor-card-list__title"
                data-testid="cor-card-list__title"
              >
                {cardListContent?.title}
              </CorTypography>
            )}
            {!!cardListContent?.description && (
              <div
                className="cor-card-list__sub-title cor-typography__body-large cor-rich-text"
                data-testid="cor-card-list__sub-title"
              >
                <CorContentstackHtml contentKey={`${BASE_KEY}.description`} />
              </div>
            )}
          </div>
        )}

        {!!cardListContent?.card_list_item?.length && (
          <div className="cor-card-list__cards__container" data-testid="cor-card-list__cards__container">
            {cardListContent?.card_list_item?.map((card, index) => (
              <CorContentstackLink
                key={index.toString()}
                className="cor-card-list__card"
                data-testid="cor-card-list__card"
                contentKey={`${BASE_KEY}.card_list_item[${index}].headline`}
                opensInNewTab={isExternalUrl(card?.headline?.href)}
              >
                <div className="cor-card-list__card__picture" data-testid="cor-card-list__card__picture">
                  {!!card?.image && <CorContentstackImage contentKey={`${BASE_KEY}.card_list_item[${index}].image`} />}
                </div>
                <div className="cor-card-list__card__content">
                  {!!card?.headline?.title && (
                    <div className="cor-card-list__card__link cor-text-link" data-testid="cor-card-list__card__link">
                      <CorTypography variant="h3" component="h3" className="cor-text-link__caret">
                        {card?.headline?.title}
                      </CorTypography>
                    </div>
                  )}

                  {!!card?.description && (
                    <div
                      className="cor-card-list__card__description cor-typography__body-regular cor-rich-text"
                      data-testid="cor-card-list__card__description"
                    >
                      <CorContentstackHtml contentKey={`${BASE_KEY}.card_list_item[${index}].description`} />
                    </div>
                  )}
                </div>
              </CorContentstackLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CorCardList);
