import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { PaymentMethodsDropdown } from 'features/payment-methods-dropdown';
import { ISelectedPaymentMethod } from 'store/invoices/reducers';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';
import { CreditCardSurchargeText } from 'components/credit-card-surcharge-text';
import { useContent } from 'hooks/use-content';
import { autopayOptIn } from 'store/autopay/actions';
import { AutopayError, PaymentMethods } from 'constants/payment-methods.enum';
import { selectIsUserCountryCanada } from 'store/auth/selectors';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import { selectAutopayOptInLoading } from 'store/autopay/selectors';
import { LoadingIndicator, LoadingIndicatorType } from 'components/loading-indicator';
import { clearAutopayError, getPaymentMethods } from 'store/payment-methods/actions';
import { selectAutopayError } from 'store/payment-methods/selectors';

import './autopay-modal.scss';

export interface IAutopayModalProps extends IModalProps {
  setAutopayToggleOn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AutopayModal: FC<IAutopayModalProps> = ({ isOpen, onClose }) => {
  const contentstackPath = 'modals.0.autopay_modal.0';
  const contentstackPathPaymentMethod = 'tabs[6].payment.content[0].invoices[0].payment_summary[0]';
  const dispatch = useDispatch();
  const { getMessageText } = useContent();

  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const isAutopayOptInLoading = useSelector(selectAutopayOptInLoading);
  const autopayError = useSelector(selectAutopayError);
  const showAutopayOptInSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    messageId: 'MSG207',
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<ISelectedPaymentMethod | null>(null);
  const selectDropdownErrorMessage = getMessageText('error_two', 'MSG222');

  const getRequestData = () => {
    if (selectedPaymentMethod) {
      if ('cardType' in selectedPaymentMethod) {
        return {
          paymentMethod: PaymentMethods.CREDIT_CARD,
          creditCardPaymentMethod: {
            cardLastFourDigits: selectedPaymentMethod['cardLastFourDigits'],
            cardType: selectedPaymentMethod['cardType'],
          },
        };
      }

      const bankAccountData = {
        accountType: selectedPaymentMethod['accountType'],
        publicAccountNumber: selectedPaymentMethod['publicAccountNumber'],
        publicRoutingNumber: selectedPaymentMethod['publicRoutingNumber'],
      };

      const paymentMethod = isUserCountryCanada ? PaymentMethods.PAD_BANK_ACCOUNT : PaymentMethods.ACH_BANK_ACCOUNT;

      const paymentData = isUserCountryCanada
        ? { padPaymentMethod: bankAccountData }
        : { achPaymentMethod: bankAccountData };

      return {
        paymentMethod,
        ...paymentData,
      };
    }
  };

  const onProceedClick = () => {
    dispatch(
      autopayOptIn.request({
        ...getRequestData(),
        onSuccessCallBack: () => {
          autopayError === AutopayError.INVALID_PAYMENT_METHOD && dispatch(clearAutopayError());
          dispatch(getPaymentMethods.request());
          showAutopayOptInSuccessModal();
        },
        onFailCallBack: () => {
          onClose && onClose();
        },
      })
    );
  };
  return (
    <Modal withBackdropClick isOpen={isOpen} onClose={onClose} className="autopay-modal" size="medium">
      {isAutopayOptInLoading && <LoadingIndicator type={LoadingIndicatorType.FULLSIZED} />}
      <ModalHeader titleContentstackPath={`${contentstackPath}.header`} />
      <div className="autopay-modal__wrapper">
        <div className="autopay-modal__field-description">
          <ContentstackText contentKey={`${contentstackPath}.enable_autopay`} />
        </div>
        <div className="autopay-modal__field">
          <PaymentMethodsDropdown
            contentstackPath={`${contentstackPathPaymentMethod}.page_content`}
            selectedPaymentMethod={selectedPaymentMethod}
            onPaymentMethodChange={(paymentMethod: ISelectedPaymentMethod) => setSelectedPaymentMethod(paymentMethod)}
            dropdownErrorMessage={selectDropdownErrorMessage}
            ignoreInvoiceSummaryRequest
          />
        </div>
        <CreditCardSurchargeText contentstackPath={contentstackPath} />
      </div>
      <ModalFooter
        className="autopay-modal__footer"
        hasCancelButton
        contentstackPath={contentstackPath}
        cancelButtonHandler={onClose}
      >
        <CorButton
          color="primary"
          className="cancel-scheduled-payment-modal__save_button"
          disabled={!selectedPaymentMethod}
          onClick={onProceedClick}
        >
          <ContentstackText contentKey={`${contentstackPath}.save_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
