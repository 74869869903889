import { FC } from 'react';
import { Formik } from 'formik';
import { omit } from 'lodash-es';
import { useSelector } from 'react-redux';
import { selectIsHouseAccountUser, selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { IPersonalInformation } from 'types/user-info';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { TextField } from 'components/forms/text-field';
import { CheckboxField } from 'components/forms/checkbox-field';
import { FormWithValidationOnSubmit, IFormState } from 'components/forms/form-with-validation-on-submit';
import { ErrorNotification } from 'components/forms/error-notification';
import { PageLeaveConfirmationModal } from 'components/page-leave-confirmation-modal';
import { ContentstackText } from 'components/contentstack';
import { useContent } from 'hooks/use-content';
import { nameValidator, phoneValidator, createValidationSchema } from 'utils/validation';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { SubmitButton } from 'components/forms/submit-button';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { CorButton } from 'components/cor-button';
import { useGetUserRole } from 'hooks/use-get-user-role';

import './edit-user-personal-info-modal.scss';

const PATH_TO_MODAL = 'modals.0.edit_info_modal.0';
const PATH_TO_FORM = `${PATH_TO_MODAL}.edit_account_personal_info_form.0`;

export interface IEditUserPersonalInfoModalProps extends IPersonalInformation, IModalProps {
  onSave: (personalInfo: IPersonalInformation) => void;
}

export const EditUserPersonalInfoModal: FC<IEditUserPersonalInfoModalProps> = ({
  isOpen = false,
  onClose = () => {},
  onSave,
  ...personalInfo
}) => {
  const { getContentByKey, getMessageText } = useContent();
  const isHouseAccountUser = useSelector(selectIsHouseAccountUser);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const { isSuperAdminOrAdmin } = useGetUserRole();

  const validationSchema = createValidationSchema({
    firstName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    lastName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    phoneNumber: phoneValidator({
      required: getMessageText('error', 'MSG008'),
      wrongFormat: getMessageText('error', 'MSG009'),
    }),
  });

  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const closeModalHandler = (dataWasChanged: boolean, resetForm: () => void) => {
    if (dataWasChanged) {
      const closingIsAllowed = window.confirm(leavingPageModalText);

      if (closingIsAllowed) {
        resetForm();
        onClose();
      }
      return;
    }
    onClose();
  };

  const formContainsEmptyRequiredFields = (formValues: IPersonalInformation) => {
    return Object.values(omit(formValues, 'email')).some((value) => value === '');
  };

  return (
    <div className="edit-user-personal-info-modal">
      <Formik
        validateOnChange={false}
        initialValues={personalInfo}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSave}
      >
        {({ dirty, isValid, handleSubmit, values, isSubmitting, setErrors, errors, resetForm }) => (
          <FormWithValidationOnSubmit<IPersonalInformation>
            className="edit-user-personal-info-modal__form"
            onChange={(event) => {
              const fieldName = (event.target as HTMLInputElement).name;
              setErrors(omit(errors, fieldName));
            }}
            {...{ handleSubmit, isValid, dirty }}
          >
            {({ setInvalidFormIsSubmitted, invalidFormIsSubmitted }: IFormState) => {
              return (
                <>
                  <Modal
                    className="edit-user-personal-info-modal__content"
                    isOpen={isOpen}
                    onClose={() => {
                      closeModalHandler(dirty, resetForm);
                      setInvalidFormIsSubmitted(false);
                    }}
                    size="large"
                    withBackdropClick
                  >
                    <ModalHeader titleContentstackPath={`${PATH_TO_MODAL}.heading`} />
                    <div className="edit-user-personal-info-modal__form">
                      {invalidFormIsSubmitted && (
                        <ErrorNotification
                          className="edit-user-personal-info-modal__general-error"
                          message={getMessageText('error', 'MSG019')}
                        />
                      )}
                      <div className="edit-user-personal-info-modal__form-fields">
                        <TextField
                          className="edit-user-personal-info-modal__field"
                          id="firstName"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.first_name_field_label`, '')}
                          name="firstName"
                        />
                        <TextField
                          className="edit-user-personal-info-modal__field"
                          id="lastName"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.last_name_field_label`, '')}
                          name="lastName"
                        />
                        <TextField
                          className="edit-user-personal-info-modal__field"
                          id="phoneNumber"
                          type="tel"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.phone_number_field_label`, '')}
                          name="phoneNumber"
                        />
                        <div className="edit-user-personal-info-modal__field">
                          <div className="edit-user-personal-info-modal__email-field-label">
                            <ContentstackText contentKey={`${PATH_TO_FORM}.email_field_label`} />
                          </div>
                          <div className="edit-user-personal-info-modal__email-field-value">{values.email}</div>
                        </div>
                      </div>

                      <div className="edit-user-personal-info-modal__subscription">
                        <CheckboxField
                          id="isMarkedForNotifications"
                          name="isMarkedForNotifications"
                          label={getContentByKey<string>(`${PATH_TO_FORM}.subscription_text`, '')}
                        />
                        {isSuperAdminOrAdmin && !isInvoiceOnlyUser && !isShopLocaleInternational() && (
                          <>
                            <CheckboxField
                              id="hasOrderSubscription"
                              name="hasOrderSubscription"
                              label={getContentByKey<string>(`${PATH_TO_FORM}.subscription_order_text`, '')}
                            />
                            {!isHouseAccountUser && (!isInvoiceOnlyUser || !isShopLocaleInternational()) && (
                              <CheckboxField
                                id="hasQuoteSubscription"
                                name="hasQuoteSubscription"
                                label={getContentByKey<string>(`${PATH_TO_FORM}.subscription_quote_text`, '')}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <ModalFooter className="edit-user-personal-info-modal__footer">
                      <SubmitButton
                        className="personal-info__submit-btn"
                        isDisabled={formContainsEmptyRequiredFields(values) || isSubmitting || !dirty}
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.save_button_title`} />
                      </SubmitButton>
                      <CorButton
                        className="text personal-info__cancel-btn"
                        onClick={() => closeModalHandler(dirty, resetForm)}
                      >
                        <ContentstackText contentKey={`${PATH_TO_FORM}.cancel_button_title`} />
                      </CorButton>
                    </ModalFooter>

                    {dirty && <PageLeaveConfirmationModal />}
                  </Modal>
                </>
              );
            }}
          </FormWithValidationOnSubmit>
        )}
      </Formik>
    </div>
  );
};
