import { isEmpty } from 'lodash-es';
import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { DEFAULT_PRICE_MODEL } from 'constants/price.enum';
import {
  IDeactivatedLineItemDetails,
  IExceededMaximumQuantitySkuInfo,
  IItemizedSkuInfo,
  IOrderDetails,
  IProprietaryItemsWithoutPriceDetails,
  ISpecialOrderSku,
} from 'types/order-details';
import types from 'store/account/action-types';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import * as shoppingListsActions from 'store/shopping-lists/actions';
import * as actions from 'store/account/actions';
import { ILineItem } from 'types/line-item';

export const orderDetailsInitialState = {
  loading: false,
  orderNumber: '',
  orderDate: '',
  ctId: '',
  lineItems: [],
  isSpecial: false,
  isCancelled: false,
  isQuoteOrder: false,
  quoteNumber: '',
  shipToLocation: {
    name: '',
    address: {
      city: '',
      state: '',
      zipCode: '',
      country: '',
      address: '',
    },
  },
  billToLocation: {
    name: '',
    address: {
      city: '',
      state: '',
      zipCode: '',
      address: '',
    },
  },
  accountInformation: {
    paymentTerms: '',
  },
  expectedDeliveryDate: '',
  invoiceNumber: '',
  priceSummary: {
    subTotal: DEFAULT_PRICE_MODEL,
    shippingSurcharge: DEFAULT_PRICE_MODEL,
    additionalCharges: DEFAULT_PRICE_MODEL,
    totalTaxes: DEFAULT_PRICE_MODEL,
    total: DEFAULT_PRICE_MODEL,
    totalItemsQuantity: 0,
    discountsExtended: {},
    totalDiscounts: {},
    additionalChargesExtended: {},
    promotion: {},
  },
  isCancellable: false,
  isOrderCancellationDenied: false,
  offline: false,
  creationDate: '',
  requestedFutureDeliveryDate: '',
  futureDate: false,
  firstName: '',
  lastName: '',
};

interface IOrderDetailsPayload {
  payload: IOrderDetails;
}

export interface IOrderDetailsState extends IOrderDetails {
  loading: boolean;
}

export interface IUpdateLineItemsOnAddSuccess {
  deactivatedSkus: IItemizedSkuInfo[];
  unavailableProprietarySkus?: IItemizedSkuInfo[];
  shortSupplyItems: IExceededMaximumQuantitySkuInfo[];
  specialOrderSkus: ISpecialOrderSku[];
  proprietaryItemsWithoutPriceDetails?: IProprietaryItemsWithoutPriceDetails[];
  deactivatedLineItems?: IDeactivatedLineItemDetails[];
  proprietarySkusWithoutPrice?: IProprietaryItemsWithoutPriceDetails[];
}

export interface IUpdateLineItemsOnFail extends IUpdateLineItemsOnAddSuccess {
  status: number;
  deactivatedItems?: IDeactivatedLineItemDetails[];
  proprietaryItemsWithoutPrice?: IProprietaryItemsWithoutPriceDetails[];
}

const updateLineItemsOnAddSuccess = (payload: IUpdateLineItemsOnAddSuccess, state: IOrderDetailsState) => {
  if (
    !isEmpty(payload.deactivatedSkus) ||
    !isEmpty(payload.unavailableProprietarySkus) ||
    !isEmpty(payload.shortSupplyItems) ||
    !isEmpty(payload.specialOrderSkus)
  ) {
    return {
      ...state,
      lineItems: state.lineItems.map((lineItem) => ({
        ...lineItem,
        isDeactivated:
          payload.deactivatedSkus?.some((item: IItemizedSkuInfo) => lineItem.itemNumber.includes(item.sku)) ||
          lineItem.isDeactivated,
        isProprietaryItemWithoutPrice:
          payload.unavailableProprietarySkus?.some((item: IItemizedSkuInfo) =>
            lineItem.itemNumber.includes(item.sku)
          ) || lineItem.isProprietaryItemWithoutPrice,
        maximumQuantity: payload.shortSupplyItems?.some((item: IExceededMaximumQuantitySkuInfo) =>
          lineItem.itemNumber.includes(item.sku)
        )
          ? 0
          : lineItem.maximumQuantity || undefined,
        isSpecialOrder: payload.specialOrderSkus?.some((item: ISpecialOrderSku) =>
          lineItem.itemNumber.includes(item.sku)
        ),
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnAddToShoppingListSuccess = (
  payload: IUpdateLineItemsOnAddSuccess,
  state: IOrderDetailsState
) => {
  if (
    !isEmpty(payload.deactivatedLineItems) ||
    !isEmpty(payload.proprietarySkusWithoutPrice) ||
    !isEmpty(payload.shortSupplyItems)
  ) {
    return {
      ...state,
      lineItems: state.lineItems.map((lineItem) => ({
        ...lineItem,
        isDeactivated:
          payload.deactivatedLineItems?.some((item: IDeactivatedLineItemDetails) =>
            lineItem.itemNumber.includes(item.itemNumber)
          ) || lineItem.isDeactivated,
        isProprietaryItemWithoutPrice:
          payload?.proprietarySkusWithoutPrice?.some((item: IProprietaryItemsWithoutPriceDetails) =>
            lineItem.itemNumber.includes(item.sku)
          ) || lineItem.isProprietaryItemWithoutPrice,
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnAddMultipleProductsToCartSuccess = (
  payload: IUpdateLineItemsOnAddSuccess,
  state: IOrderDetailsState
) => {
  if (
    !isEmpty(payload.deactivatedSkus) ||
    !isEmpty(payload.unavailableProprietarySkus) ||
    !isEmpty(payload.shortSupplyItems)
  ) {
    return {
      ...state,
      lineItems: state.lineItems.map((lineItem) => ({
        ...lineItem,
        isDeactivated:
          payload.deactivatedSkus.some((item: IItemizedSkuInfo) => lineItem.itemNumber.includes(item.sku)) ||
          lineItem.isDeactivated,
        isProprietaryItemWithoutPrice:
          payload.unavailableProprietarySkus?.some((item: IItemizedSkuInfo) =>
            lineItem.itemNumber.includes(item.sku)
          ) || lineItem.isProprietaryItemWithoutPrice,
        maximumQuantity: payload.shortSupplyItems.some((item: IExceededMaximumQuantitySkuInfo) =>
          lineItem.itemNumber.includes(item.sku)
        )
          ? 0
          : lineItem.maximumQuantity || undefined,
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnAddToShoppingListFail = (error: IUpdateLineItemsOnFail, state: IOrderDetailsState) => {
  if (error.status === 400 && (!isEmpty(error.deactivatedItems) || !isEmpty(error.proprietaryItemsWithoutPrice))) {
    return {
      ...state,
      lineItems: state.lineItems.map((lineItem) => ({
        ...lineItem,
        isDeactivated:
          error.deactivatedItems?.some((item: IDeactivatedLineItemDetails) =>
            lineItem.itemNumber.includes(item.itemNumber)
          ) || lineItem.isDeactivated,
        isProprietaryItemWithoutPrice:
          error.proprietaryItemsWithoutPrice?.some((item: IProprietaryItemsWithoutPriceDetails) =>
            lineItem.itemNumber.includes(item.sku)
          ) || lineItem.isProprietaryItemWithoutPrice,
      })),
    };
  } else {
    return state;
  }
};

const updateLineItemsOnFail = (error: IUpdateLineItemsOnFail, state: IOrderDetailsState) => {
  if (
    error.status === 400 &&
    (!isEmpty(error.deactivatedItems) ||
      !isEmpty(error.proprietaryItemsWithoutPrice) ||
      !isEmpty(error.shortSupplyItems))
  ) {
    return {
      ...state,
      lineItems: state.lineItems.map((lineItem) => ({
        ...lineItem,
        isDeactivated:
          error?.deactivatedItems?.some((item: IDeactivatedLineItemDetails) =>
            lineItem.itemNumber.includes(item.itemNumber)
          ) || lineItem.isDeactivated,
        isProprietaryItemWithoutPrice:
          error.proprietaryItemsWithoutPrice?.some((item: IProprietaryItemsWithoutPriceDetails) =>
            lineItem.itemNumber.includes(item.sku)
          ) || lineItem.isProprietaryItemWithoutPrice,
        maximumQuantity:
          error?.shortSupplyItems &&
          error.shortSupplyItems.some((shortSupplyItem: IExceededMaximumQuantitySkuInfo) =>
            lineItem.itemNumber.includes(shortSupplyItem.sku)
          )
            ? 0
            : lineItem.maximumQuantity || undefined,
      })),
    };
  } else {
    return state;
  }
};

export const orderDetails = createReducer<IOrderDetailsState>(
  {
    [actions.getOrderDetailsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      orderNumber: '',
      creationDate: '',
      loading: true,
      createdByRepresentative: null,
    }),
    [actions.getOrderDetailsActionConstants[Actions.SUCCESS]]: (state, { payload }: IOrderDetailsPayload) => ({
      ...state,
      ...payload,
      priceSummary: payload.priceSummary,
      lineItems: payload.lineItems,
      loading: false,
      additionalChargesExtended: payload.priceSummary.additionalChargesExtended,
    }),
    [actions.getOrderDetailsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.SET_ORDER_CANCELLATION_DENIED]: (state, { payload }: IOrderDetailsPayload) => ({
      ...state,
      isOrderCancellationDenied: payload.isOrderCancellationDenied,
    }),
    [actions.cancelOrderActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.cancelOrderActionConstants[Actions.SUCCESS]]: (state, { payload }: IOrderDetailsPayload) => ({
      ...state,
      ...payload,
      priceSummary: payload.priceSummary,
      lineItems: payload.lineItems,
      loading: false,
    }),
    [shoppingCartActions.reorderProductsActionConstants[Actions.SUCCESS]]: (state, { payload }) =>
      updateLineItemsOnAddSuccess(payload, state),
    [actions.cancelOrderActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      lineItems: state.lineItems.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.itemNumber),
      })),
    }),
    [actions.getAccountInformationActionConstants[Actions.SUCCESS]]: (state) => state,
    [shoppingListsActions.addItemsToShoppingListsActionConstants[Actions.SUCCESS]]: (state, { payload }) =>
      updateLineItemsOnAddToShoppingListSuccess(payload, state),
    [shoppingCartActions.addMultipleProductsToCartActionConstants[Actions.SUCCESS]]: (state, { payload }) =>
      updateLineItemsOnAddMultipleProductsToCartSuccess(payload, state),
    [shoppingListsActions.addItemsToShoppingListsActionConstants[Actions.FAIL]]: (state, { error }) =>
      updateLineItemsOnAddToShoppingListFail(error, state),
    [shoppingCartActions.addProductToCartActionConstants[Actions.FAIL]]: (state, { error }) =>
      updateLineItemsOnFail(error, state),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      lineItems: state.lineItems.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.itemNumber),
      })),
    }),
  },
  orderDetailsInitialState
);
