import { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import { get } from 'lodash-es';
import Select, {
  components,
  Props,
  ValueContainerProps,
  MultiValue,
  SingleValue,
  OptionProps,
  DropdownIndicatorProps,
} from 'react-select';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { OptionsCompositeType } from 'types/dropdown';

import './custom-dropdown.scss';

export interface IOptionType {
  label: string;
  value: any;
}

export interface ILinkOptionType {
  label: string;
  value: string;
  href: string;
  title: string;
}

export interface ICustomDropdownProps extends Partial<Props<IOptionType>> {
  items: OptionsCompositeType;
  dropdownIcon?: string;
  renderOption?: (props: OptionProps<IOptionType>) => JSX.Element;
  hasValueAlt?: boolean;
  multiValueTitle?: string;
  dropdownLabel?: string;
  label?: string;
  ref?: MutableRefObject<any | undefined>;
  errorMessage?: string;
  dropdownCategoryLabel?: () => JSX.Element;
}

const DEFAULT_MENU_HEIGHT = 300;
const OPTION_ITEM_HEIGHT = 44;

export const CustomDropdown: FC<ICustomDropdownProps> = ({
  defaultValue,
  items,
  value,
  isSearchable = false,
  hideSelectedOptions = false,
  onChange,
  dropdownIcon,
  className,
  isDisabled = false,
  isMulti = false,
  closeMenuOnSelect = true,
  renderOption,
  formatGroupLabel,
  formatOptionLabel,
  multiValueTitle,
  isClearable = false,
  placeholder,
  hasValueAlt = false,
  inputId,
  onBlur,
  classNamePrefix = 'select',
  ref,
  errorMessage,
  dropdownCategoryLabel,
}) => {
  const [error, setError] = useState(false);
  const [maxMenuHeight, setMaxMenuHeight] = useState(DEFAULT_MENU_HEIGHT);
  const [selectedValue, setSelectedValue] = useState<IOptionType | MultiValue<IOptionType>>();
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownClass = classNames('select', className, {
    'select--multiple-values': isMulti,
    'select--is-error': error,
  });

  const DropdownIndicator = (props: DropdownIndicatorProps<IOptionType>) => {
    const valueProp = props.getValue();
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <label className="select__label" htmlFor={inputId}>
            {get(valueProp, '0.label')}
          </label>
          <span className="select__dropdown-icon" />
        </components.DropdownIndicator>
      )
    );
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps<IOptionType>) => {
    const valueProp = props.getValue();

    const CustomValueContainer = useCallback(
      () => (
        <>
          <div className="select__icon">
            <img src={dropdownIcon} alt="dropdown icon" />
          </div>
          <components.ValueContainer {...props}>
            <div
              title={hasValueAlt ? get(valueProp, '0.value') : null}
              className="select__single-value-container-wrapper"
            >
              {children}
            </div>
          </components.ValueContainer>
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return dropdownIcon ? (
      <CustomValueContainer />
    ) : (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  };

  const Option = ({ children, ...props }: OptionProps<IOptionType>) => {
    return renderOption ? (
      renderOption({ children, ...props })
    ) : (
      <components.Option {...props}>{children}</components.Option>
    );
  };

  const MultiValue = ({ children, ...props }: any) => {
    return props.selectProps.value?.length > 0 ? (
      <components.ValueContainer {...props}>
        {props.selectProps.value.length} {multiValueTitle}
      </components.ValueContainer>
    ) : (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  };

  const dropdownComponentsMap = [
    {
      DropdownIndicator,
      ValueContainer,
      Option,
    },
    { DropdownIndicator, MultiValue, Option },
  ];

  const getMaxMenuHeight = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    const height = document.documentElement.clientHeight - (rect?.bottom ?? 0) - OPTION_ITEM_HEIGHT;
    const menuHeight = Math.round(height / OPTION_ITEM_HEIGHT) * OPTION_ITEM_HEIGHT;

    return height > DEFAULT_MENU_HEIGHT || menuHeight <= 0 ? DEFAULT_MENU_HEIGHT : menuHeight;
  };

  const onChangeInternal = (value: MultiValue<IOptionType> | SingleValue<IOptionType>, actionMeta) => {
    if (value) {
      setError(false);
      setSelectedValue(value);
    }

    onChange && onChange(value, actionMeta);
  };

  const onBlurInternal: React.FocusEventHandler<HTMLInputElement> | undefined = (event) => {
    if (!selectedValue || isEmpty(selectedValue)) {
      setError(true);
    }

    onBlur && onBlur(event);
  };

  const onMenuOpen = () => {
    setMaxMenuHeight(getMaxMenuHeight());
  };

  return (
    <>
      <div className={dropdownClass} ref={containerRef}>
        {dropdownCategoryLabel && dropdownCategoryLabel()}
        <Select
          ref={ref}
          inputId={inputId}
          components={!isMulti ? dropdownComponentsMap[0] : dropdownComponentsMap[1]}
          classNamePrefix={classNamePrefix}
          value={value}
          defaultValue={defaultValue}
          options={items}
          isSearchable={isSearchable}
          onChange={onChangeInternal}
          hideSelectedOptions={hideSelectedOptions}
          isDisabled={isDisabled}
          isMulti={isMulti}
          onBlur={onBlurInternal}
          isClearable={isClearable}
          closeMenuOnSelect={closeMenuOnSelect}
          placeholder={placeholder}
          formatGroupLabel={formatGroupLabel}
          formatOptionLabel={formatOptionLabel}
          blurInputOnSelect={false}
          maxMenuHeight={maxMenuHeight}
          onMenuOpen={onMenuOpen}
        />
      </div>
      {error && errorMessage && <div className={`${dropdownClass}-message`}>{errorMessage}</div>}
    </>
  );
};
