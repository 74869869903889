import './cor-footer.scss';
import './cor-footer__legal.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CorFooterDesktop } from 'corporate-components/cor-layout/cor-footer-desktop';
import { CorFooterMobile } from 'corporate-components/cor-layout/cor-footer-mobile';
import { ICSUtilityNavData } from 'components/layout/cor-header/cor-header.interface';
import { ContentstackContext } from 'context/contentstack';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useContentstackSearchTags } from 'hooks/use-contentstack-search-tags';
import { selectIsAuthorised, selectIsImpersonateModeOn } from 'store/auth/selectors';
import { logOutClickHandler } from 'utils/link-utils';
import { isInternationalSite } from 'utils/get-locale-params';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { logout } from 'store/auth/actions';

const CorFooter = () => {
  const { isMobile } = useBreakpoint();
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const dispatch = useDispatch();

  const handleLogOutClick = React.useCallback(
    (e) => {
      if (isImpersonateModeOn) {
        e.preventDefault();
      } else if (isShopLocaleInternational()) {
        dispatch(logout);
      } else {
        logOutClickHandler(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isImpersonateModeOn]
  );

  if (isMobile) return <CorFooterMobile onLogout={handleLogOutClick} />;
  return <CorFooterDesktop onLogout={handleLogOutClick} />;
};

const CorFooterWrapper = () => {
  const isLoggedInUser = useSelector(selectIsAuthorised);
  const [locale] = useLocalStorage('locale', null);

  const data: ICSUtilityNavData =
    useContentstackSearchTags([
      {
        contentstackParams: isLoggedInUser ? 'footer-authenticated' : 'footer-unauthenticated',
        contentTypeName: 'unified_footer',
        include: [
          'footer_labels',
          'newsletter_form.error_messages.messages',
          'newsletter_form.error_two.messages',
          'support_block',
          'copyright_block',
        ],
        ...(isInternationalSite(locale) && { locale: locale }),
      },
    ])[0] || {};

  const contextProps = { ...data, custom_is_logged_in: isLoggedInUser };

  return (
    <div className="cor-footer">
      <ContentstackContext.Provider value={contextProps}>
        <CorFooter />
      </ContentstackContext.Provider>
    </div>
  );
};

export default React.memo(CorFooterWrapper);
