import { FC, FormEvent, useState } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { TextField, ITextField } from 'components/forms/text-field';
import { compose } from 'utils/compose';

import './password-field.scss';

interface IPasswordFieldProps extends ITextField {
  passwordVisibilityToggle?: boolean;
  autoComplete?: boolean;
}

export const PasswordField: FC<IPasswordFieldProps> = ({
  onClick,
  autoComplete,
  passwordVisibilityToggle,
  ...restProps
}) => {
  const [, meta, { setValue, setError }] = useField(restProps);
  const [passwordVisible, setPasswordVisibility] = useState(false);

  const passwordVisibilityClass = classNames({
    'password-field__value--visible': passwordVisible,
    'password-field__value--hidden': !passwordVisible,
  });

  const internalOnClickHandler = () => {
    if (meta.error) {
      setValue('');
      setError(undefined);
    }
  };

  return passwordVisibilityToggle ? (
    <div className="password-field">
      <TextField type={passwordVisible ? 'text' : 'password'} autoComplete={autoComplete && 'on'} {...restProps}>
        <div className={passwordVisibilityClass} onClick={() => setPasswordVisibility(!passwordVisible)} />
      </TextField>
    </div>
  ) : (
    <TextField
      type="password"
      onClick={compose<FormEvent<HTMLFormElement>>(onClick, internalOnClickHandler)}
      autoComplete={autoComplete && 'on'}
      {...restProps}
    />
  );
};
