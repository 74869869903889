import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerInput, DatesProvider } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { startOfTomorrow, format, addDays } from 'date-fns';
import { useContent } from 'hooks/use-content';
import { Routes } from 'constants/routes.enum';
import { IInvoiceItem } from 'types/invoices';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { selectIsUserCountryCanada } from 'store/auth/selectors';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/icon-calendar-gray.svg';
import {
  scheduleBankAccountPayment,
  scheduleCreditCardPayment,
  schedulePadPayment,
} from 'store/schedule-payment/actions';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import { IScheduleCardPaymentPayload } from 'store/schedule-payment/sagas/schedule-card-payment';
import { IScheduleBankAccountPaymentPayload } from 'store/schedule-payment/sagas/schedule-bank-payment';
import { ISchedulePadPaymentPayload } from 'store/schedule-payment/sagas/schedule-pad-payment';
import { IBankAccounts, ICreditCards } from 'store/payment-methods/reducers';
import { ContentstackHtml, ContentstackMessage, ContentstackText } from 'components/contentstack';
import { ISelectedPaymentMethod } from 'store/invoices/reducers';
import { useLocalStorage } from 'hooks/use-local-storage';
import { InvoiceStatus } from 'constants/invoices.enum';
import { getLocaleURLByCode } from 'utils/get-locale-params';
import { IComments } from 'pages/payment-summary/payment-summary';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useGetTranslatedUrl } from 'hooks/use-get-translated-url';
import 'dayjs/locale/en';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/en-ca';

import './schedule-payment-modal.scss';

export interface IPaymentDocument {
  adjustmentReason?: string;
  comment?: string;
  confirmedBalance?: number;
  documentKey: string;
}
export interface ISchedulePaymentModalProps extends IModalProps {
  paymentDocuments: IInvoiceItem[];
  paymentMethod: ISelectedPaymentMethod | null;
  commentsInputValue?: IComments[];
}

export const SchedulePaymentModal: FC<ISchedulePaymentModalProps> = ({
  isOpen,
  onClose,
  paymentDocuments,
  paymentMethod,
  commentsInputValue,
}) => {
  const contentStackPath = 'modals.[0].schedule_payment_modal[0]';
  const { isMobile } = useBreakpoint();
  const { getContentByKey } = useContent();
  const dispatch = useDispatch();
  const [locale] = useLocalStorage('locale', null);
  const datePickerRef = useRef<HTMLButtonElement>(null);
  const MAX_AVAILABLE_DAYS_FOR_PAYMENT = 59;
  const minDate = startOfTomorrow();
  const maxDate = addDays(startOfTomorrow(), MAX_AVAILABLE_DAYS_FOR_PAYMENT);

  const [paymentDate, setDate] = useState<Date>(minDate);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bankAccountDetails, setBankAccountDetails] = useState<IBankAccounts>();
  const [creditCardDetails, setCreditCardDetails] = useState<ICreditCards>();
  const [isPaymentMethodCreditCard, setIsPaymentMethodCreditCard] = useState(false);

  const history = useHistory();
  const [, setTransactionStatus] = useLocalStorage('transactionStatus', InvoiceStatus.OPEN);
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const { getTranslatedUrl } = useGetTranslatedUrl();
  const paymentsUrl = getTranslatedUrl({
    usEnglishUrl: Routes.AccountPayments,
    caFrenchUrl: Routes.CanadaFrenchPayments,
  });

  const [opened, { open, close }] = useDisclosure(false);

  const showSchedulePaymentSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    modalTitle: getContentByKey(`${contentStackPath}.success_message_title`, ''),
    children: (
      <ContentstackHtml
        component="span"
        contentKey={`${contentStackPath}.success_message_new`}
        interpolateParams={{ localeUrl: getLocaleURLByCode(locale) }}
      />
    ),
  });

  const showSchedulePaymentFailModal = useSuccessErrorMessageModal({
    type: 'error',
    modalTitle: getContentByKey(`${contentStackPath}.error_message_title`, ''),
    children: <ContentstackMessage messageId={'MSG189'} type="error" />,
    autoClose: false,
    hasCrossButton: true,
  });

  const checkPaymentMethodType = (paymentMethod: ICreditCards | IBankAccounts): paymentMethod is ICreditCards => {
    return !isEmpty(paymentMethod['cardType']);
  };

  useEffect(() => {
    if (paymentMethod) {
      if (checkPaymentMethodType(paymentMethod)) {
        setIsPaymentMethodCreditCard(true);
        setCreditCardDetails(paymentMethod);
      } else {
        setBankAccountDetails(paymentMethod);
      }
    }
  }, [paymentMethod]);

  const requiredPaymentDocumentDetails = paymentDocuments.map(({ adjustmentReason, confirmedBalance, documentKey }) => {
    const paymentInvoiceComment = commentsInputValue?.find((invoice) => {
      return invoice?.selectedDocumentKey === documentKey && !!invoice?.comment;
    });
    return {
      adjustmentReason,
      confirmedBalance,
      documentKey,
      ...(paymentInvoiceComment?.comment && { comment: paymentInvoiceComment.comment }),
    };
  });

  const onSuccessfulSchedulePayment = () => {
    showSchedulePaymentSuccessModal();
    setTimeout(() => {
      history.push(paymentsUrl);
    }, 3000);
  };

  const scheduleBankPayment = () => {
    if (bankAccountDetails && paymentDate) {
      const { accountType, publicRoutingNumber, publicAccountNumber } = bankAccountDetails;
      if (!isUserCountryCanada) {
        dispatch(
          scheduleBankAccountPayment.request<IScheduleBankAccountPaymentPayload>({
            paymentDocuments: requiredPaymentDocumentDetails,
            accountType: accountType,
            publicRoutingNumber: publicRoutingNumber,
            publicAccountNumber: publicAccountNumber,
            scheduledDate: format(paymentDate, 'yyyy-MM-dd'),
            onSuccessCallBack: onSuccessfulSchedulePayment,
            onFailCallBack: showSchedulePaymentFailModal,
          })
        );
        return;
      }
      dispatch(
        schedulePadPayment.request<ISchedulePadPaymentPayload>({
          paymentDocuments: requiredPaymentDocumentDetails,
          accountType: accountType,
          routingNumber: publicRoutingNumber,
          publicAccountNumber: publicAccountNumber,
          scheduledDate: format(paymentDate, 'yyyy-MM-dd'),
          onSuccessCallBack: onSuccessfulSchedulePayment,
          onFailCallBack: showSchedulePaymentFailModal,
        })
      );
    }
  };

  const scheduleCreditCard = () => {
    if (creditCardDetails && paymentDate) {
      const { cardLastFourDigits, cardType } = creditCardDetails;
      dispatch(
        scheduleCreditCardPayment.request<IScheduleCardPaymentPayload>({
          cardLastFourDigits: cardLastFourDigits,
          cardType: cardType,
          paymentDocuments: requiredPaymentDocumentDetails,
          scheduledDate: format(paymentDate, 'yyyy-MM-dd'),
          onSuccessCallBack: onSuccessfulSchedulePayment,
          onFailCallBack: showSchedulePaymentFailModal,
        })
      );
    }
  };

  const submitScheduledPayment = () => {
    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);
    setTransactionStatus(InvoiceStatus.SCHEDULED);
    isPaymentMethodCreditCard ? scheduleCreditCard() : scheduleBankPayment();
  };

  const onDateChange = (date: Date) => {
    setDate(date);
    close();
  };

  return (
    <>
      <Modal className="schedule-payment-modal" isOpen={isOpen} onClose={onClose} withBackdropClick={!opened}>
        <ModalHeader iconType="error" contentstackPathIcon={`${contentStackPath}.calendar_icon`}>
          <ContentstackText contentKey={`${contentStackPath}.header_title`} />
        </ModalHeader>
        <div className="schedule-payment-modal__content-wrapper">
          <div className="schedule-payment-modal__description">
            <ContentstackHtml contentKey={`${contentStackPath}.description`} />
          </div>
          <div className="schedule-payment-modal__calendar-container">
            <DatesProvider settings={{ locale }}>
              <DatePickerInput
                ref={datePickerRef}
                dropdownType={isMobile ? 'modal' : 'popover'}
                label={getContentByKey('modals.[0].schedule_payment_modal[0].select_date_label', '')}
                placeholder={getContentByKey('modals.[0].schedule_payment_modal[0].select_date_label', '')}
                defaultDate={minDate}
                firstDayOfWeek={0}
                minDate={minDate}
                value={paymentDate}
                onChange={onDateChange}
                level="month"
                modalProps={{ opened: opened, onClose: close }}
                onClick={() => open()}
                maxDate={maxDate}
                icon={<CalendarIcon />}
                classNames={{
                  day: 'schedule-payment-modal__date-day',
                  calendarHeaderLevel: 'schedule-payment-modal__date-calendar-header-level',
                  weekday: 'schedule-payment-modal__date-weekday',
                  monthCell: 'schedule-payment-modal__date-month',
                  calendarHeaderControl: 'schedule-payment-modal__date-calendar-header-arrows',
                  month: 'schedule-payment-modal__date-month',
                  calendarHeader: 'schedule-payment-modal__date-calendar-header',
                  label: 'schedule-payment-modal__date-select-label',
                  input: 'schedule-payment-modal__date-input',
                }}
              />
            </DatesProvider>
          </div>
        </div>
        <ModalFooter hasCancelButton cancelButtonHandler={onClose} contentstackPath={contentStackPath}>
          <CorButton onClick={submitScheduledPayment}>
            <ContentstackText contentKey={`${contentStackPath}.submit_button_label`} />
          </CorButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
