import { ICorFeaturedContentEntry } from 'corporate-components/cor-featured-content';
import { ICSCorImage } from 'corporate-interfaces';
import { ISortParams } from 'types/product-sort-options';

export interface IAlgoliaProps {
  categoryKeys: string[];
  facets: { key: string; values: string[] }[];
  filters: string[];
  limit: number;
  offset: number;
  sortOptions: ISortParams;
  text: string;
  pageOffset: number;
  isTotalShopProductsCounted?: boolean;
  nonProductOffset: number;
}

export interface IAlgoliaResponseMeta {
  page?: {
    total_results?: number;
    total_pages?: number;
    current?: number;
    size?: number;
  };
}

export interface IAlgoliaResponse {
  globalPageTypeMeta?: IAlgoliaResponseMeta;
  globalPageTypeContents?: IGlobalPageTypeContent[];
  c_shop_product_cards?: ICorFeaturedContentEntry[];
  total?: number;
  totalSkus?: number;
}

export interface IAlgoliaRawResponse {
  searchAllUrl?: string;
  globalPageTypeContents?: IGlobalPageTypeContent[];
  plpProducts?: {
    result?: IProduct[];
    facets?: IFacet[];
    categories?: ICategory[];
    total?: number;
    totalSkus?: number;
    limit?: number;
    offset?: number;
    content?: string;
    dawnBrands?: string[];
    queryAfterRemoval?: string;
  };
}

export interface IProduct {
  skus?: IProductSku[];
  name?: string;
  productKey?: string;
  imageUrl?: string;
  isInCart?: boolean;
}

export interface IProductSku {
  brand?: string;
  newArrival?: boolean;
  sku?: string;
  flavor?: string;
  color?: string;
  isDefault?: boolean;
  isDiscontinued?: boolean;
  isPhasingOut?: boolean;
  isSpecialOrder?: boolean;
  isOutOfStock?: boolean;
  gtin?: string;
  seasonalBadgeId?: string;
  replacementSkus?: string[];
  price?: { amount?: string; currencySymbol?: string };
}

export interface IGlobalPageTypeContent {
  total: number;
  limit: number;
  offset: number;
  url: string;
  title: string;
  categories: ICategory[];
  facets: IFacet[];
  pageType: IPageType[];
  c_cards: ICorFeaturedContentEntry[];
}

export interface ICategory {
  categoryKey: string;
  categoryName: IAlgoliaContentTypesEnum;
  isSelected: boolean;
  count: number;
}

export const enum IAlgoliaContentTypesEnum {
  RecipeDetailPage = 'recipe_detail_page',
  InsightsDetailPage = 'insights_detail_page',
  NewsPage = 'news_page',
  StandardPage = 'standard_page',
}

export const enum IAlgoliaTagsEnum {
  FAQ = 'faq',
  CorporateProducts = 'corporate-product',
}

export interface IFacet {
  label: string;
  key: string;
  count: number;
  selectedValues: string[];
  terms: { [key: string]: number };
}

export interface IPageType {
  title: string;
  type: IAlgoliaContentTypesEnum;
  url: string;
  meta_title?: string;
  meta_description?: string;
  publication_date?: Date;
  recipe_occasion?: string[];
  recipe_product_type?: string[];
  recipe_flavors?: string[];
  tags: string[];
  filter_tags: string[];
  background_image: string;
  background_brand_folder_image: string;
  background_image_alt_text: string;
  search_image: string;
  search_brand_folder_image: string;
  search_image_alt_text: string;
  short_description: string;
}

export interface IAlgoliaCard {
  pageUrl?: { href?: string; title?: string };
  title?: string;
  category?: string;
  description?: string;
  publicationDate?: string;
  image?: ICSCorImage;
  sku?: IProductSku;
  productKey?: string;
}

export interface IAlgoliaCards extends Array<IAlgoliaCard> {}
