import { AxiosError, AxiosRequestConfig } from 'axios';
import { ActionType } from 'typesafe-actions';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import request from 'api';
import { history } from 'store';
import { doRequest } from 'store/api/actions';
import types from 'store/api/action-types';
import { selectIsLoggedInUser } from 'store/auth/selectors';
import { Routes } from 'constants/routes.enum';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';

export const makeRequest = async (params: AxiosRequestConfig) => {
  return request({ ...params });
};

export function* apiSagaHandler({
  payload: { actions, data, url, method = 'get', headers = {}, cancelToken },
}: ActionType<typeof doRequest>) {
  const requestParams = {
    url,
    method,
    data,
    cancelToken,
    headers,
    withCredentials: true,
  };

  try {
    const response = yield call(makeRequest, requestParams);
    yield put(actions.success(response.data));
    return yield response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    const response = axiosError.response;
    const locale = localStorage.getItem('locale');
    const isCanadaFrenchLocale = locale?.includes(CorContentStackLanguages.FRENCH_CANADA);

    if (response && [401, 403].includes(response?.status)) {
      const isLoggedInUser = yield select(selectIsLoggedInUser);

      if (isLoggedInUser) {
        const state = history.location.pathname === Routes.SelectLocationPage ? undefined : { from: history.location };

        history.replace({
          pathname: getLocalizedShopUrl(isCanadaFrenchLocale ? Routes.CanadaFrenchLoginPage : Routes.LoginPage),
          state,
        });
      }
    }
    yield put(actions.fail({ ...response?.data, status: response?.status }));
  }
}

export default function* apiSaga() {
  yield takeEvery(types.API_DO_REQUEST, apiSagaHandler);
}
