import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { approveQuote, approveQuoteActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';

export interface IApproveQuoteValues {}
export interface IApproveQuotePayload {
  quoteNumber: string;
  quoteDetails: {
    comment: string;
    estimatedDeliveryDate: string;
    expireDate: string;
    price: string;
    quantity: string;
  };
  onSuccessCallback: () => void;
}

export function* approveQuoteSagaHandler({ payload: { quoteNumber, quoteDetails } }: IAction<IApproveQuotePayload>) {
  yield put(doRequest(approveQuote, `${Endpoints.QUOTES}/${quoteNumber}/approve`, 'patch', quoteDetails));
}

export function* approveQuoteSuccessCallBackSagaHandler({ payload }: IAction<IApproveQuotePayload>) {
  yield take(approveQuoteActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    yield payload.onSuccessCallback();
  }
}

export default function* approveQuoteSaga() {
  yield takeLatest(approveQuoteActionConstants[Actions.REQUEST], approveQuoteSagaHandler);
  yield takeLatest(approveQuoteActionConstants[Actions.REQUEST], approveQuoteSuccessCallBackSagaHandler);
}
