import { FC } from 'react';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { CorporatePageType } from 'corporate-constants/page-types.constants';
import { ICSCorMetadata } from 'corporate-interfaces';
import { ContentstackText } from 'components/contentstack';
import { IconWithHover } from 'components/icon-with-hover';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ICSUtilityNavLogo } from 'components/layout/cor-header/cor-header.interface';
import { CorShareButton } from './components/cor-share-button/cor-share-button';
import './cor-share-social.scss';

const SHARE_COMMON_SOCIAL = 'common[0].shared_recipe_detail_page[0]';
const INSIGHT_TITILE = 'social_links_insight_title';
const RECIPE_TITILE = 'social_links_recipe_title';

export interface ISocialNetworks {
  icon: ICSUtilityNavLogo;
  metadata: ICSCorMetadata;
}

export const CorShareSocial: FC = () => {
  const { getContentByKey } = useContent();
  const { isMobile } = useBreakpoint();
  const title = getContentByKey('title', '');
  const socialNetworks: ISocialNetworks[] = getContentByKey(`${SHARE_COMMON_SOCIAL}.social_links`, []);
  const corporatePageType = getContentByKey('contentTypeName', CorporatePageType.Insights);
  const isInsight = corporatePageType === CorporatePageType.Insights;

  return (
    <div className="cor-share-social">
      <p className={'cor-share-social__title'}>
        <ContentstackText contentKey={`${SHARE_COMMON_SOCIAL}.${isInsight ? INSIGHT_TITILE : RECIPE_TITILE}`} />
      </p>

      {!!socialNetworks?.length && (
        <div className="cor-share-social__icons">
          {socialNetworks.map(({ icon }) => {
            const iconUrl = corContentstackImageLinkResolver(icon)?.url || '';
            const socialIconUrl = isMobile && icon.mobile_url ? icon.mobile_url.href : icon.link_url.href;

            return (
              <CorShareButton
                key={icon?.link_url?.title}
                shareUrl={socialIconUrl}
                url={window.location.toString()}
                title={title}
                data-testid={`${icon?.link_url?.title}-icon`}
              >
                <IconWithHover icon={iconUrl} hoverIcon={iconUrl} alt={icon?.image_alt_text || icon?.link_url?.title} />
              </CorShareButton>
            );
          })}
        </div>
      )}
    </div>
  );
};
