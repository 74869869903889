import { FC } from 'react';
import { CorContentstackLink } from 'corporate-components';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { ICorExtension, ICSCorImage } from 'corporate-interfaces';
import { IMainMenuResource } from 'components/layout/cor-header/cor-header.interface';
import './main-nav-resource-item.scss';
import { isExternalUrl } from 'utils/is-external-url';

export interface IMainNavResourceMenu {
  menu: IMainMenuResource[];
}

export const MainNavResourceMenu: FC<IMainNavResourceMenu> = ({ menu }) => {
  return (
    <ul className="main-subnav-content__highlights__list">
      {menu.map((item: IMainMenuResource, index: number) => {
        const { file, url, subtitle, title, imageCover, icon, brandfolderIcon } = item;

        const imageIcon: ICSCorImage = {
          brand_folder_image: [
            {
              ...brandfolderIcon,
              cdn_url: brandfolderIcon?.src,
              extension: (brandfolderIcon?.type as unknown) as ICorExtension,
            },
          ],
          image: { url: icon?.src },
        };

        const resolvedImage = corContentstackImageLinkResolver(imageIcon);

        return (
          <li key={`${item.url}_${index}`}>
            <CorContentstackLink
              data={{
                href: file?.src ? file.src : url,
                title: file?.title || `${title}${subtitle}`,
              }}
              opensInNewTab={file?.src ? isExternalUrl(file.src) : false}
              className={`highlight-panel highlight-panel--${imageCover ? 'left' : 'center'}`}
            >
              <div className={`highlight-image highlight-image--${imageCover ? 'cover' : 'icon'}`}>
                <div>
                  <img src={resolvedImage?.url} alt={title} />
                </div>
              </div>
              <div className="highlight-title">
                {title && <span className="highlight-title__main">{title}</span>}
                {subtitle && <span className="highlight-title__sub">{subtitle}</span>}
              </div>
            </CorContentstackLink>
          </li>
        );
      })}
    </ul>
  );
};
