import React, { useState, SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useContent } from 'hooks/use-content';
import { useHideGlobalModalHandler, useLocationsSelectConfirmationModal } from 'hooks/use-global-modal';
import { selectCustomerLocations } from 'store/customer-login/selectors';
import { submitLocation } from 'store/customer-login/actions';
import { ISubmitLocationPayload } from 'store/customer-login/sagas/submit-location';
import { selectUser, selectUserLocationId } from 'store/auth/selectors';

import './utility-locations.scss';

export const UtilityLocations: React.FC = () => {
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const customerLocations = useSelector(selectCustomerLocations);
  const userLocationId = useSelector(selectUserLocationId);
  const user = useSelector(selectUser);
  const userLocation = customerLocations.find((item) => item.value === user.locationId);
  const defaultLocation = userLocation?.isDeleted ? { ...userLocation, label: `*${userLocation.label}` } : userLocation;
  const [preselectedLocation, setPreselectedLocation] = useState(defaultLocation);
  const deletedLabel = getContentByKey('common[0].deleted_location_label', '');
  const showLocationsSelectConfirmationModal = useLocationsSelectConfirmationModal();
  const closeGlobalModal = useHideGlobalModalHandler();

  useEffect(() => {
    if (defaultLocation?.value !== preselectedLocation?.value) {
      showLocationsSelectConfirmationModal({
        onLocationSubmit,
        preselectedLocation,
        onClose: () => {
          closeGlobalModal();
          setPreselectedLocation(defaultLocation);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedLocation]);

  const onLocationSelect = (location: any) => {
    setPreselectedLocation(location);
    return;
  };

  const onLocationSubmit = () => {
    dispatch(
      submitLocation.request<ISubmitLocationPayload>({ locationNumber: preselectedLocation?.value })
    );
    setPreselectedLocation(defaultLocation);
    closeGlobalModal();
  };

  return (
    <div className="cor-account-menu__locations-submenu">
      <ul className="cor-account-menu__locations-submenu-list">
        {customerLocations.map((location) => (
          <li
            className={classNames('cor-account-menu__locations-submenu-item', {
              'cor-account-menu__locations-submenu-item--selected': userLocationId === location.value,
            })}
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation();
              onLocationSelect(location);
            }}
            key={location.value}
          >
            {location.isDeleted ? (
              <div
                className={classNames('cor-account-menu__locations-submenu-item--selected', {
                  'cor-account-menu__locations-submenu-item--deleted': userLocationId !== location.value,
                })}
              >
                <span
                  className={classNames('cor-account-menu__locations-submenu-item__icon', {
                    'cor-account-menu__locations-submenu-item__icon--notice': userLocationId === location.value,
                  })}
                />
                <span className="cor-account-menu__locations-submenu-item__label">
                  {location.label} {deletedLabel}
                </span>
              </div>
            ) : (
              location.label
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
