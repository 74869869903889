import { isEmpty } from 'lodash-es';
import { FC, SyntheticEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { components, MultiValue, OptionProps, SingleValue } from 'react-select';
import { useContent } from 'hooks/use-content';
import { inviteNewUser } from 'store/account/actions';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ILocationCardProps } from 'components/locations-list/location-card';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { Roles } from 'constants/roles.enum';
import { CustomTooltip } from 'components/custom-tooltip';
import { TextField } from 'components/forms/text-field';
import { createValidationSchema, emailValidator } from 'utils/validation';
import { IInviteNewUserPayload } from 'types/user-info';
import { FormWithValidationOnSubmit, IFormState } from 'components/forms/form-with-validation-on-submit';
import { ErrorNotification } from 'components/forms/error-notification';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './invite-new-user-modal.scss';

export interface IInviteNewUserModalProps extends IModalProps {
  isOpen: boolean;
  onClose: () => void;
  locations: ILocationCardProps[];
  selectedLocations: string[];
  inviteNewUserSuccessCallback: () => void;
  unselectAllLocations: () => void;
}

export interface IInviteNewUserValues {
  accountType: IDropdownOptions;
  email: string;
  locations: IDropdownOptions[];
}

export interface IDropdownOptions {
  value: string;
  label: string;
}

export const InviteNewUserModal: FC<IInviteNewUserModalProps> = ({
  isOpen,
  onClose,
  locations,
  selectedLocations = [],
  inviteNewUserSuccessCallback,
}) => {
  const { getContentByKey, getMessageText } = useContent();
  const dispatch = useDispatch();
  const [serverErrorId, setServerErrorId] = useState('');
  const setInvalidFormIsSubmittedRef = useRef<(value: boolean) => void>(() => {});

  const contentstackPath = 'modals.0.invite_new_user_modal.0';
  const multiValueTitle = getContentByKey(`${contentstackPath}.location_access_multi_value_title`, '');
  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');
  const clientErrorMessage = getMessageText('error', 'MSG019');
  const selectDropdownErrorMessage = getMessageText('error_two', 'MSG222');

  const accountTypes = [
    { value: Roles.ADMIN, label: getContentByKey(`${contentstackPath}.roles.0.admin`, '') },
    { value: Roles.USER, label: getContentByKey(`${contentstackPath}.roles.0.user`, '') },
  ];

  const validationSchema = createValidationSchema({
    email: emailValidator({
      required: getMessageText('error', 'MSG006'),
      wrongFormat: getMessageText('error', 'MSG007'),
    }),
  });

  const customerLocations: IDropdownOptions[] = locations
    .filter((location) => !location.isDeleted)
    .map((item) => {
      return { value: item.locationId, label: item.name };
    });

  const preSelectedLocations = customerLocations.filter((location) => selectedLocations.includes(location.value));

  const initialValues: IInviteNewUserValues = {
    accountType: { value: '', label: '' },
    email: '',
    locations: preSelectedLocations,
  };

  const renderOption = (className, children, props) => {
    return (
      <components.Option className={className} {...props}>
        <div className={`${className}-dropdown__item`}>{children}</div>
      </components.Option>
    );
  };

  const locationsRenderOption = ({ children, ...props }: OptionProps<IOptionType>) => {
    return renderOption('locations', children, props);
  };

  const accountRenderOption = ({ children, ...props }: OptionProps<IOptionType>) => {
    return renderOption('account', children, props);
  };

  const onFailCallback = (status: number, messageId: string) => {
    const alreadyRegisteredUserStatusCode = 409;

    if (status === alreadyRegisteredUserStatusCode) {
      setServerErrorId(messageId);
    }
  };

  const onSubmit = (values: IInviteNewUserValues, { resetForm }: FormikHelpers<IInviteNewUserValues>) => {
    const payload: IInviteNewUserPayload = {
      accountType: values.accountType.value,
      email: values.email,
      locationsId: values.locations.map((location) => location.value),
      onSuccessCallback: () => {
        resetForm();
        inviteNewUserSuccessCallback();
      },
      onFailCallback: onFailCallback,
    };

    dispatch(inviteNewUser.request(payload));
  };

  const closeModalHandler = (dataWasChanged: boolean, resetForm: () => void) => {
    if (dataWasChanged) {
      const closingIsAllowed = window.confirm(leavingPageModalText);

      if (closingIsAllowed) {
        resetForm();
        setServerErrorId('');
        onClose();
      }
      return;
    }
    onClose();
  };

  const containsEmptyRequiredFields = (values: IInviteNewUserValues) => {
    return values.email === '' || values.accountType.value === '' || isEmpty(values.locations);
  };

  const renderDropdownLabel = (className: string, contentstackLabelPath: string) => {
    return (
      <p className={className}>
        <ContentstackText contentKey={contentstackLabelPath} />
      </p>
    );
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, dirty, isValid, handleSubmit, setFieldValue, errors, setErrors, resetForm }) => {
        return (
          <Modal
            size="medium"
            className="invite-new-user-modal"
            isOpen={isOpen}
            onClose={() => {
              closeModalHandler(dirty, resetForm);
              setInvalidFormIsSubmittedRef.current?.(false);
            }}
            withBackdropClick
          >
            <>
              <FormWithValidationOnSubmit<IInviteNewUserValues> {...{ handleSubmit, isValid, dirty }}>
                {({ setInvalidFormIsSubmitted, invalidFormIsSubmitted }: IFormState) => {
                  setInvalidFormIsSubmittedRef.current = setInvalidFormIsSubmitted;
                  return (
                    <>
                      <ModalHeader titleContentstackPath={`${contentstackPath}.heading`} />
                      {(invalidFormIsSubmitted || serverErrorId) && (
                        <ErrorNotification
                          messageId={serverErrorId}
                          message={clientErrorMessage}
                          interpolateParams={{ email: values.email }}
                        />
                      )}
                      <div className="invite-new-user-modal__content">
                        <div className="invite-new-user-modal__field invite-new-user-modal__locations">
                          <div className="invite-new-user-modal__locations-value">
                            <CustomDropdown
                              inputId="invite-new-user-modal__locations-dropdown--id"
                              name="locations"
                              items={customerLocations}
                              renderOption={locationsRenderOption}
                              placeholder={getContentByKey<string>(
                                `${contentstackPath}.location_access_placeholder`,
                                ''
                              )}
                              defaultValue={preSelectedLocations}
                              isMulti
                              multiValueTitle={multiValueTitle}
                              closeMenuOnSelect={customerLocations.length === 1}
                              onChange={(locationsOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                                const newOption = locationsOption as IOptionType[];
                                setFieldValue('locations', newOption);
                              }}
                              className={'invite-new-user-modal__locations-dropdown'}
                              errorMessage={selectDropdownErrorMessage}
                              dropdownCategoryLabel={() =>
                                renderDropdownLabel(
                                  'invite-new-user-modal__label invite-new-user-modal__locations-label',
                                  `${contentstackPath}.location_access_label`
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="invite-new-user-modal__field invite-new-user-modal__account">
                          <div className="invite-new-user-modal__account-container">
                            <div className="invite-new-user-modal__account-value">
                              <CustomDropdown
                                inputId="invite-new-user-modal__account-dropdown--id"
                                name="accountType"
                                items={accountTypes}
                                renderOption={accountRenderOption}
                                placeholder={''}
                                onChange={(accountTypeOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                                  setFieldValue('accountType', accountTypeOption as IOptionType);
                                }}
                                className={'invite-new-user-modal__account-dropdown'}
                                errorMessage={selectDropdownErrorMessage}
                                dropdownCategoryLabel={() =>
                                  renderDropdownLabel(
                                    'invite-new-user-modal__label invite-new-user-modal__account-label',
                                    `${contentstackPath}.account_type_label`
                                  )
                                }
                              />
                            </div>
                            <CustomTooltip tooltipId="accountType">
                              <div>
                                <ContentstackText contentKey={`${contentstackPath}.admin_tooltip`} />
                              </div>
                              <div>
                                <ContentstackText contentKey={`${contentstackPath}.user_tooltip`} />
                              </div>
                            </CustomTooltip>
                          </div>
                        </div>

                        <div className="invite-new-user-modal__field invite-new-user-modal__email">
                          <TextField
                            id="email"
                            label={getContentByKey<string>(`${contentstackPath}.email_label`, '')}
                            name="email"
                            placeholder={getContentByKey<string>(`${contentstackPath}.email_placeholder`, '')}
                            onChange={(event: SyntheticEvent) => {
                              if (errors.email) {
                                setErrors({});
                              }
                              setFieldValue('email', (event.target as HTMLInputElement).value);
                              setServerErrorId('');
                            }}
                          />
                        </div>
                      </div>
                      <ModalFooter
                        contentstackPath={contentstackPath}
                        cancelButtonHandler={() => {
                          closeModalHandler(dirty, resetForm);
                          setInvalidFormIsSubmitted(false);
                        }}
                        hasCancelButton
                      >
                        <CorButton
                          className="invite-new-user-modal__send-invite-button"
                          disabled={containsEmptyRequiredFields(values)}
                          type="submit"
                        >
                          <ContentstackText contentKey={`${contentstackPath}.send_invite_button`} />
                        </CorButton>
                      </ModalFooter>
                    </>
                  );
                }}
              </FormWithValidationOnSubmit>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
};
