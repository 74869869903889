import './cor-content-column.scss';

import classNames from 'classnames';
import React, { FC } from 'react';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { CorTypography } from 'corporate-ui';
import { ICSCorColumnContent } from 'corporate-components/cor-two-column/cor-two-column';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { get } from 'lodash-es';
import { CorContentstackVideo } from 'corporate-components/cor-contentstack/cor-contentstack-video';
import { corContentstackVideoLinkResolver } from 'corporate-utils/cor-contentstack-video-link-resolver';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { ICSCorButton } from 'corporate-interfaces';
import { CorButtonLink } from 'components/cor-button-link';
import { CustomLink } from 'components/custom-link';

export type ContentColumnPosition = 'left' | 'right';
export type ContentColumnVariant = 'cta' | 'fifty-fifty' | 'inset';

export interface ICorContentColumnProps {
  column: ICSCorColumnContent;
  position: ContentColumnPosition;
  variant: ContentColumnVariant;
}

export const CorContentColumn: FC<ICorContentColumnProps> = ({ column, variant, position }) => {
  const { image, text: paragraph, icon_text_group, video } = column;
  const { icon, text: heading, button } = icon_text_group || {};
  const { isEmpty } = video ? corContentstackVideoLinkResolver(video) : { isEmpty: true };
  const imageAsset = image ? corContentstackImageLinkResolver(image) : null;
  const videoAsset = video && !isEmpty ? corContentstackVideoLinkResolver(video) : null;
  const isVideoMuted = !!video?.mute ? 1 : 0;
  const columnModifierClasses = `cor-content-${variant}`;
  const asset = (button: ICSCorButton) => corContentstackAssetLinkResolver(button);

  if (!videoAsset && !imageAsset && !paragraph && !heading) {
    return null;
  }
  if (videoAsset && variant !== 'cta') {
    return (
      <div
        className={classNames(
          'cor-content-column',
          'cor-content-column__video',
          `${columnModifierClasses}__column`,
          `${columnModifierClasses}__column--video`,
          `${columnModifierClasses}__column-${position}`
        )}
      >
        <CorContentstackVideo
          data={videoAsset}
          showControls={true}
          showPlayButton={true}
          isBackground={false}
          youtubeConfig={
            videoAsset.isYoutube
              ? {
                  autoplay: 0,
                  controls: 1,
                  loop: 0,
                  mute: isVideoMuted,
                }
              : null
          }
        />
      </div>
    );
  }

  if (imageAsset && variant !== 'cta') {
    if (imageAsset.link_url?.href) {
      return (
        <CustomLink
          title={imageAsset?.link_url?.title || ''}
          url={imageAsset.link_url.href}
          className={classNames(
            'cor-content-column__image',
            `${columnModifierClasses}__column`,
            `${columnModifierClasses}__column--image`,
            `${columnModifierClasses}__column-${position}`
          )}
        >
          <div className={`${columnModifierClasses}__image`}>
            <img src={imageAsset.url} alt={imageAsset.image_alt_text} />
          </div>
        </CustomLink>
      );
    }
    return (
      <div
        className={classNames(
          'cor-content-column__image',
          `${columnModifierClasses}__column`,
          `${columnModifierClasses}__column--image`,
          `${columnModifierClasses}__column-${position}`
        )}
      >
        <div className={`${columnModifierClasses}__image`}>
          <img src={imageAsset.url} alt={imageAsset.image_alt_text} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        `${columnModifierClasses}__column`,
        `${columnModifierClasses}__column-${position}`,
        `${columnModifierClasses}__column--text`
      )}
    >
      {icon && variant === 'cta' && (
        <div className={`${columnModifierClasses}__icon`}>
          <img className={`${columnModifierClasses}__icon-image`} src={icon.url} alt={icon.title} />
        </div>
      )}
      {heading && (
        <div className={`${columnModifierClasses}__heading`}>
          <CorTypography variant={'h2'} component={'div'} color={'dark-gray'}>
            {heading}
          </CorTypography>
        </div>
      )}
      {paragraph && (
        <div
          className={classNames(
            `${columnModifierClasses}__paragraph`,
            'cor-content-column__paragraph',
            'cor-rich-text'
          )}
        >
          <CorContentstackHtml contentKey={'text'} />
        </div>
      )}
      {button && button?.button_style && button.button?.title && (
        <div className={`${columnModifierClasses}__buttons`}>
          <CorButtonLink
            className={`${columnModifierClasses}__button`}
            color={(button?.button_style || 'primary').toLowerCase()}
            href={asset(button)?.url ?? button?.button?.href}
            target={button?.open_in_a_new_tab ? '_blank' : '_self'}
            iconUrl={get(button, 'button_icon[0].cdn_url')}
            iconPosition={(button?.button_alignment as string) || 'left'}
            asset={asset(button)?.url}
          >
            {button?.button?.title}
          </CorButtonLink>
        </div>
      )}
    </div>
  );
};
