import { FC, useState } from 'react';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { useContent } from 'hooks/use-content';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './cancel-quote-request-modal.scss';

export interface ICancelQuoteRequestModalProps extends IModalProps {
  quoteNumber: string;
  cancelQuoteHandler: (cancelReason: string, quoteNumber: string) => void;
}

export const CancelQuoteRequestModal: FC<ICancelQuoteRequestModalProps> = ({
  isOpen,
  onClose = () => {},
  quoteNumber,
  cancelQuoteHandler,
}) => {
  const contentstackPath = 'modals.0.cancel_quote_modal.0';
  const { getContentByKey } = useContent();
  const [cancelReason, setCancelReason] = useState('');

  const onChangeCancelReason = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    setCancelReason(e.currentTarget.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={false}
      className="cancel-quote-request-modal"
      withBackdropClick
    >
      <ModalHeader titleContentstackPath={`${contentstackPath}.cancel_quote_modal_header`} />
      <div className="cancel-quote-request-modal__comment">
        <label className="cancel-quote-request-modal__comment-label" htmlFor="cancelQuoteComment">
          <ContentstackText contentKey={`${contentstackPath}.cancellation_reason_label`} />
        </label>
        <textarea
          rows={6}
          maxLength={300}
          className="cancel-quote-request-modal__comment-input"
          id="cancelQuoteComment"
          value={cancelReason}
          onChange={onChangeCancelReason}
          placeholder={getContentByKey(`${contentstackPath}.cancellation_reason_placeholder_text`, '')}
        />
      </div>
      <ModalFooter className="cancel-quote-request-modal__buttons">
        <CorButton className="text" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.go_back_button_label`} />
        </CorButton>
        <CorButton
          className="cancel-quote-request-modal__cancel"
          onClick={() => cancelQuoteHandler(cancelReason, quoteNumber)}
        >
          <ContentstackText contentKey={`${contentstackPath}.cancel_request_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
