import {
  BankAccountHolderType,
  BankAccountType,
  PadBankAccountType,
  PaymentMethods,
} from 'constants/payment-methods.enum';
import { IBankAccounts } from 'store/payment-methods/reducers';

export const getAccountTypeKeys = (
  account: IBankAccounts,
  contentStackPaymentCommonPath: string,
  isUserCountryCanada: boolean
) => {
  const accountType = account?.accountType?.includes(BankAccountType.CHECKING)
    ? BankAccountType.CHECKING
    : BankAccountType.SAVINGS;
  const personalAccountTypeByCountry = isUserCountryCanada ? PadBankAccountType.PERSONAL : BankAccountType.PERSONAL;
  const accountHolderType = account?.accountType?.includes(personalAccountTypeByCountry)
    ? BankAccountHolderType.PERSONAL
    : BankAccountHolderType.BUSINESS;

  return {
    accountHolderTypeKey: `${contentStackPaymentCommonPath}.account_holder_type.${accountHolderType.toLowerCase()}`,
    accountTypeKey: `${contentStackPaymentCommonPath}.account_type.${accountType.toLowerCase()}`,
  };
};

export const getAccountHolderType = (accountType: string | undefined) =>
  accountType?.includes(BankAccountType.PERSONAL) || accountType?.includes(BankAccountHolderType.PERSONAL.toUpperCase())
    ? BankAccountHolderType.PERSONAL
    : BankAccountHolderType.BUSINESS;

export const getAccountHolderTypeKey = (accountType: string | undefined, paymentMethod: keyof typeof PaymentMethods) =>
  paymentMethod === PaymentMethods.CREDIT_CARD
    ? 'tabs[6].payment.content[0].payment_common.account_holder_type.credit'
    : `tabs[6].payment.content[0].payment_common.account_holder_type.${getAccountHolderType(
        accountType
      ).toLowerCase()}`;
