import { FC, PropsWithChildren } from 'react';
import { LivePreviewLayout } from 'layouts/live-preview-layout';
import { DocumentHead } from 'components/document-head';
import { GlobalModal } from 'components/modals/global-modal';

export const LivePreviewRoute: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LivePreviewLayout>
      <DocumentHead />
      <GlobalModal />
      {children}
    </LivePreviewLayout>
  );
};
