import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { addProductToCart, addProductToCartActionConstants } from 'store/shopping-cart/actions';
import { sendAddToCart } from 'utils/analytics';
import { IAction } from 'types/actions';
import { ILineItem } from 'types/line-item';
import { IError } from 'components/modals/import-shopping-list-modal';

export interface IAddProductToCartPayload {
  sku: string;
  quantity: number;
  sendGA?: boolean;
  onErrorCallBack?: (error: string, errorStatus?: number, errorInfo?: IError) => void;
  onSuccessCallBack?: (exceededLineItemFromResponse?: ILineItem) => void;
}

export function* addProductToCartOnSuccessCallBackSagaHandler({ payload }: IAction<IAddProductToCartPayload>) {
  const {
    payload: { lineItems },
  } = yield take(addProductToCartActionConstants[Actions.SUCCESS]);
  const exceededLineItemFromResponse = lineItems.find((lineItem: ILineItem) => lineItem.itemNumber === payload.sku);

  if (payload.sendGA) {
    sendAddToCart(payload.sku);
  }

  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack(exceededLineItemFromResponse);
  }
}

export function* addProductToCartOnFailCallBackSagaHandler({ payload }: IAction<IAddProductToCartPayload>) {
  const { error } = yield take(addProductToCartActionConstants[Actions.FAIL]);
  const errorMessagesFromContentStack: { [index: string]: string } = {
    400: 'MSG049',
  };

  if (payload.onErrorCallBack) {
    payload.onErrorCallBack(errorMessagesFromContentStack[error.status], error.status, error);
  }
}

export function* addProductToCartSagaHandler({ payload }: IAction<IAddProductToCartPayload>) {
  const { quantity, sku } = payload;
  yield put(
    doRequest<IAddProductToCartPayload>(addProductToCart, Endpoints.CART, 'put', { quantity, sku })
  );
}

export default function* addProductToCartSaga() {
  yield takeLatest(addProductToCartActionConstants[Actions.REQUEST], addProductToCartOnSuccessCallBackSagaHandler);
  yield takeLatest(addProductToCartActionConstants[Actions.REQUEST], addProductToCartOnFailCallBackSagaHandler);

  yield takeLatest(addProductToCartActionConstants[Actions.REQUEST], addProductToCartSagaHandler);
}
